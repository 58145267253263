import { VStack, Text, Flex, Badge, Box, CircularProgress, CircularProgressLabel, Image, Stack, SkeletonCircle } from "@chakra-ui/react"
import { getFriends, getFriendsWithLiveScore } from "../helper/friendRequestsHelper"
import { getLevel, getLiveScoreLevel } from "../../../helpers/levelConversionHelper"
import { GlobalDataContext } from "../../global/globalDataContext"
import { useContext } from "react"
import { FriendsContext } from "../friendsContext"
import { FriendRequestWithUserAndLiveScoreType } from "../types/friendRequestWithUserAndLiveScoreType"
import { FriendRequestWithUserType } from "../types/friendRequestWithUserType"
import { getPointsFromLiveScoreHelper } from "../../../helpers/getPointsFromLiveScoreHelper"
import { LiveScoreType } from "../../../types/liveScoreType"
import { useTranslation } from "next-i18next"

type Props = {
    friends:FriendRequestWithUserAndLiveScoreType[]|FriendRequestWithUserType[]|undefined
}

export const GameFriendsScore = ({friends}:Props) => {

    const {t} = useTranslation();
    const {
        setIsLeftSide,
        setIsRightSide,
        language,
        showLeftSide,
        setShowLeftSide,
        showRightSide
    } = useContext(GlobalDataContext)
    
    const {
        canShowFriends,
        friendRequestWithUser,
        setShowModalInviteFriends,
        isLoadingFriends
    } = useContext(FriendsContext)
    
    const episodeStartTime = 0
    return (
        <VStack
            w={{base: showRightSide?"100px": "0px", md: "100px", "2xl" : "140px"  }}
            minWidth={{base: showRightSide?"100px":"0px",  md: "100px", "2xl" : "140px"  }}   
            h={"var(--doc-height)"}
            alignSelf={"start"}
            
            color={"white"}
            overflowY={"hidden"}
            overflowX={"hidden"}
            position="relative"
            _after={{
                "position": "absolute",
                "left": 0,
                "width": {base:"0px", "2xl": "0px"},
                "top": 0,
                "height": "100%",
                "display": "block",
                "content": '""'
            }}
        >
            <VStack
                background={"linear-gradient(90deg, #1c1c1c 0%, rgb(35 35 35) 39px);"}
                w={{base:"100px","2xl":"140px"}}
                minWidth={{base:"100px","2xl":"140px"}}
                h="full"
                paddingLeft={{base:"0px"}}
            >
                <Text 
                    display={{base:"none","2xl":"block"}}
                    w={"full"} 
                    textAlign={"center"} 
                    p={2} 
                    background={"linear-gradient(90deg, #983706 0%, rgb(241 101 41) 39px);"} 
                    color={"white"}
                >
                    {t("friends.longTitle")}
                </Text>
                <Text 
                    display={{base:"block","2xl":"none"}}
                    w={"full"} 
                    textAlign={"center"} 
                    p={2} 
                    background={"linear-gradient(90deg, #983706 0%, rgb(241 101 41) 23px);"} 
                    color={"white"}
                >
                    {t("friends.shortTitle")}
                </Text>
                <Text 
                    w={"full"} 
                    textAlign={"center"} 
                    px={1} 
                >
                    <small>{t("friends.ranking")}</small>
                </Text>
                <VStack height="full" width="full" overflowY={"auto"} overflowX={"hidden"} marginBottom={{base:"80px","2xl":"120px"}}>
                    {isLoadingFriends && 
                    <Stack>
                        <SkeletonCircle size='20' mb={5}/>
                        <SkeletonCircle size='20' mb={5}/>
                        <SkeletonCircle size='20' mb={5}/>
                    </Stack>
                    }
                    { !isLoadingFriends && friends && (friends).length > 0 && ("liveScore" in friends[0] ? 
                        (getFriendsWithLiveScore(friends as FriendRequestWithUserAndLiveScoreType[]).sort((a:FriendRequestWithUserAndLiveScoreType, b:FriendRequestWithUserAndLiveScoreType)=> {
                            if (a.liveScore && b.liveScore) {   
                                return getPointsFromLiveScoreHelper(b.liveScore, episodeStartTime) - getPointsFromLiveScoreHelper(a.liveScore, episodeStartTime);
                            }                                
                            return 0;
                        })):
                        (getFriends(friends as FriendRequestWithUserType[]))
                        ).map((item, index) =>
                            <Flex 
                                key={index}
                                w={{base:"88px","2xl":"full"}}
                                background={index % 2 != 0?"#23232396":"transparent"}
                                py={1}
                                direction={"column"}
                                textAlign={"center"}
                                minH={{base:"80px","2xl":"120px"}}
                                position="relative"
                                justifyContent={"center"}
                                >
                                    <Badge 
                                        transform={{base:"scale(0.8)","2xl":"scale(1)"}}
                                        borderRadius={"35%"}
                                        borderTopLeftRadius={0}
                                        borderTopRightRadius={0}
                                        background={"linear-gradient(to right,#E44D26 0%,#E44D26 50%,#F16529 50%,#F16529 100%)"}
                                        color={"white"}
                                        position={"absolute"}
                                        top={0}
                                        left={2}
                                        fontSize={"1em"}
                                        w={"25px"}
                                        h={"25px"}
                                        margin={"auto"}
                                        clipPath={"polygon(0% 0%, 100% 0%, 90% 90%, 50% 100%, 10% 90%)"}
                                        zIndex={2}
                                    >
                                        {index+1}
                                    </Badge>
                                <Box
                                    display={{base:"block","2xl":"none"}}  
                                    position="relative"
                                    > 
                                    <CircularProgress  
                                        size={"48px"}
                                        value={("liveScore" in item ? getLiveScoreLevel(getPointsFromLiveScoreHelper(item.liveScore as LiveScoreType[], episodeStartTime)):getLevel(item.state.points) || 0) * 10}
                                        color={"#dd6b20"}
                                        trackColor={"#b5b5b578"}
                                    >
                                        <CircularProgressLabel
                                            p={"4px"}                                        
                                        > 
                                            <Image     
                                                p={"3px"}                                
                                                src={item.user.photoUrl?item.user.photoUrl:`https://ui-avatars.com/api/?name=${item.user.name}`}
                                                width={"40px"}
                                                height={"40px"}                                        
                                                borderRadius={"50%"}                                        
                                            />
                                        </CircularProgressLabel>
                                    </CircularProgress> 
                                    
                                    <Badge position="absolute" bottom={0} right={1} mx='1' colorScheme='orange' textTransform={"none"}>
                                        {"liveScore" in item ? getPointsFromLiveScoreHelper(item.liveScore as LiveScoreType[], episodeStartTime):item.state.points}
                                    </Badge>
                                </Box>
                                <Box
                                    display={{base:"none","2xl":"block"}}  
                                    position="relative"
                                    > 
                                    <CircularProgress  
                                        size={"68px"}
                                        value={("liveScore" in item ? getLiveScoreLevel(getPointsFromLiveScoreHelper(item.liveScore as LiveScoreType[], episodeStartTime)):getLevel(item.state.points) || 0) * 10}
                                        color={"#dd6b20"}
                                        trackColor={"#b5b5b578"}
                                    >
                                        <CircularProgressLabel
                                            p={"4px"}                                        
                                        > 
                                            <Image     
                                                p={"6px"}                                
                                                src={item.user.photoUrl?item.user.photoUrl:`https://ui-avatars.com/api/?name=${item.user.name}`}
                                                width={"60px"}
                                                height={"60px"}                                        
                                                borderRadius={"50%"}                                        
                                            />
                                        </CircularProgressLabel>
                                    </CircularProgress> 
                                    
                                    <Badge position="absolute" bottom={0} right={4} mx='1' colorScheme='orange' textTransform={"none"}>
                                        {"liveScore" in item ? getPointsFromLiveScoreHelper(item.liveScore as LiveScoreType[], episodeStartTime):item.state.points}
                                    </Badge>
                                </Box>
                                <Text 
                                    transform={{base:"scale(0.8)","2xl":"scale(1)"}}
                                    color={"#fff"} 
                                    px={2} 
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    >
                                    {item.user.name}
                                </Text>
                            </Flex>
                    )}
                </VStack>
                <>
                    <Flex 
                        w={"full"}
                        py={1}
                        direction={"column"}
                        textAlign={"center"}
                        onClick={()=>setShowModalInviteFriends(true)}
                        cursor={"pointer"}
                        position="absolute"
                        bottom={0}
                        >
                        <Box 
                            w={{base:"40px","2xl":"60px"}}
                            h={{base:"40px","2xl":"60px"}}
                            margin="auto"    
                            textAlign={"center"}      
                            p={{base:2,"2xl":5}}        
                            background={"linear-gradient(to right,#E44D26 0%,#E44D26 50%,#F16529 50%,#F16529 100%)"}      
                            borderRadius={"50%"}    
                            fontSize={"20px"}
                            lineHeight={"100%"}
                        > 
                            +
                        </Box>
                        <Text 
                            display={{base:"none","2xl":"block"}}
                            color={"#fff"} 
                            px={2} 
                            transform={{base:"scale(0.8)","2xl":"scale(1)"}}
                        >
                            {t("friends.addFriend")}
                        </Text>
                        <Text 
                            display={{base:"block","2xl":"none"}}
                            color={"#fff"} 
                            px={2} 
                            transform={{base:"scale(0.8)","2xl":"scale(1)"}}
                        >
                            {t("friends.add")}
                        </Text>
                    </Flex>
                    
                </>
            </VStack>
        </VStack>
    )
}