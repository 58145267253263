import { Avatar, Badge, Box, Button, CloseButton, Flex, HStack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { keyframes, css } from '@emotion/react';
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import TextToSpeechButton from "@/app/layout/global/textToSpeechButton";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { congratsFeed, doubleCardFriendsMessage, explainChase, explainEggs, explainHowToFeed, explainQuest, explainShopping, firstHello, firstHelloInAppBrowsing, obtainFood, obtainMonster, otherHello, rainingHello } from "../helper/anaDiscussionHelper";
import { FriendsContext } from "../../friends/friendsContext";
import { hasDuplicateCreatureId } from "@/app/helpers/hasDuplicateCreatureId";
import { FaCheck, FaRegCalendarAlt } from "react-icons/fa";
import {DailyChallengeModal} from "@/app/layout/game/modal/dailyChallengeModal"

export default function AnaDiscussion() {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose}= useDisclosure()

    const router = useRouter();
    const currentPath = router.pathname;
    const zoomAndShake = keyframes`
        0% {
            transform: scale(0.7) ;
            opacity: 0.75;
        }
        50% {
            transform: scale(1.1) ;
            opacity: 1;
        }
        100% {
            transform: scale(1) ;
        }
        `;
    
    const [totalChallenges, setTotalChallenges] = useState<number>(0)
    const [challengesWin, setChallengesWin] = useState<number>(0)
    const [forceStopTalking, setForceStopTalking] = useState(false)
    const [showDiscussion, setShowDiscussion] = useState(false);
    const conversationScrollBottom = useRef(null);
    const scrollToBottom = ()=> {
        if (conversationScrollBottom.current !==null) {
            const element = conversationScrollBottom.current as Element;
            element.scrollIntoView()
        }
    }
    
    const {
        messages,
        addMessageWithId,
        numberOfUnreadMessages,
        markMessageAsRead,
        markMessageAsTreated,
        voiceTalking,
        setVoiceTalking,
        character,
        defaultCharacter
    } = useContext(NotificationContext)

    const {
        myCreatures,
        addNewCreature,
        addFood,
        actionHistory,
        anaDiscussionStatus,
        //setAnaDiscussionStatus,
        updateAnaDiscussionStatus,
        isGlobalDataUserLoading,
        addOrUpdateActionHistory,
        checkActionHistory,
        config,
        isReadyForAna,
        userState,
        myItems,
        getChallenge,
        showChallengeIndexes,
        emptyChallengeIndexes
    } = useContext(GlobalDataUserContext)

    const {
        categoryId,
        isRaining,
        browserActive,
        inAppBrowsing
    } = useContext(GlobalDataContext)

    const {
        user,
        isAuthLoading
    } = useContext(AuthenticationContext)

    const {
        friendRequests
    } = useContext(FriendsContext)

    const voiceTalkingRef = useRef(voiceTalking);

    const onCloseChallenge = () => {    
        onClose()
        emptyChallengeIndexes()
    }

    useEffect(() => {
        voiceTalkingRef.current = voiceTalking;
    }, [voiceTalking]);

    useEffect(()=>{
        if ( process.env.NODE_ENV==="development" ) {    
            console.log("discussion markasread start", messages)   
        }
        const timer = setTimeout(() => {
            if ( !voiceTalkingRef.current) {
                markMessageAsRead()
            }
        },10000)

        return () => clearTimeout(timer)
    },[])

    useEffect(()=>{
        const challenges = getChallenge()
        if ( challenges) {
            setTotalChallenges(challenges.length)
            const winChallenges = challenges.filter((c:any) => c.checked).length
            setChallengesWin(winChallenges)
        }
    },[actionHistory])

    useEffect(() => {
        scrollToBottom()
        if ( messages.find(m => m.status === "unread") ) {
            const timer = setTimeout(() => {
                if ( !voiceTalkingRef.current) {
                    markMessageAsRead()
                }
            },10000)

            return () => clearTimeout(timer)
        }
    },[messages])

    useEffect(()=>{
        if (!voiceTalking ) {
            //const timer = setTimeout(() => {
                markMessageAsRead()
            //},7500)

            //return () => clearTimeout(timer)
        }
    },[voiceTalking])

    useEffect(()=>{
        scrollToBottom()   
        if ( process.env.NODE_ENV==="development" ) {    
            console.log("discussion", messages)   
        }
        if ( showDiscussion) {
            markMessageAsRead()
        } 
    },[showDiscussion])

    useEffect(()=>{
        if ( isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && anaDiscussionStatus.firstFeedMessage !== true && actionHistory?.find(a => a.type === "feed") ){
            congratsFeed(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_FIRST_FEED_MESSAGE", true)
        }
    },[isReadyForAna,isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, actionHistory])

    useEffect(()=>{
        if ( isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && myCreatures?.length === 1 && (!userState || (userState && (userState?.food ==0 || !userState?.food ))) && ( anaDiscussionStatus.obtainFirstFoodMessage !== true) ){
            obtainFood(addMessageWithId,t, <Button onClick={()=>{
                addFood(100)
                markMessageAsTreated("obtainFood")
            }} colorScheme="linkedin">{t("discussion.obtainFood")}</Button>, character?.translationKey)
            updateAnaDiscussionStatus("SET_OBTAIN_FIRST_FOOD_MESSAGE", true)
        }
    },[isReadyForAna, isAuthLoading, userState, isGlobalDataUserLoading,anaDiscussionStatus,myCreatures])

    useEffect(()=>{
        if (  isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && userState && userState?.food ==100 && ( anaDiscussionStatus.explainHowToFeed !== true) ){
            explainHowToFeed(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_EXPLAIN_HOW_TO_FEED", true)       
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading,userState, anaDiscussionStatus])

    useEffect(()=>{
        if (  isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && currentPath=="/[language]/[categoryId]/[categoryName]/chase/[chaseId]" && ( anaDiscussionStatus.firstMessage == true) && ( anaDiscussionStatus.explainHowToPlay !== true) ) {
            explainChase(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_EXPLAIN_HOW_TO_PLAY", true)
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, currentPath, anaDiscussionStatus])


    useEffect(()=>{
        if (  isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && currentPath=="/[language]/[categoryId]/[categoryName]/quest/[questId]" && ( anaDiscussionStatus.firstMessage == true) && ( anaDiscussionStatus.explainHowToPlayQuest !== true) ) {
            explainQuest(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_EXPLAIN_HOW_TO_PLAY_QUEST", true)
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, currentPath, anaDiscussionStatus])

    useEffect(()=>{
        if (  isReadyForAna && !isAuthLoading && myItems && myItems.length  && !isGlobalDataUserLoading && anaDiscussionStatus && currentPath=="/[language]/[categoryId]/[categoryName]/eggs" && ( anaDiscussionStatus.firstMessage == true) && ( anaDiscussionStatus.explainEggs !== true) ) {
            explainEggs(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_EXPLAIN_EGGS", true)
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, currentPath, anaDiscussionStatus, myItems])

    useEffect(()=>{
        console.log("check", browserActive, isReadyForAna, isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, categoryId, myCreatures?.length, currentPath, anaDiscussionStatus?.firstMessage, anaDiscussionStatus?.obtainFirstCreatureMessage)
        if ( isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && categoryId  && myCreatures?.length==0 && currentPath=="/[language]/[categoryId]/[categoryName]" && ( anaDiscussionStatus.firstMessage == true) && ( anaDiscussionStatus.obtainFirstCreatureMessage !== true) ) {
            obtainMonster(addMessageWithId,t, <Button onClick={()=>{
                addNewCreature(null, Date.now()-1000*60*60*24*5)
                markMessageAsTreated("obtainMonster")                
            }} colorScheme="linkedin">{t("discussion.getMonster")}</Button>, character?.translationKey)
            updateAnaDiscussionStatus("SET_OBTAIN_FIRST_CREATURE_MESSAGE", true)                       
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, categoryId])

    useEffect(()=>{
        if (  isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && currentPath=="/[language]/shopping" && ( anaDiscussionStatus.firstMessage == true) && ( anaDiscussionStatus.explainShopping !== true) ) {
            explainShopping(addMessageWithId,t, character?.translationKey)
            updateAnaDiscussionStatus("SET_EXPLAIN_SHOPPING", true)                       
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, categoryId])


    useEffect(()=>{
        if ( isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && user && friendRequests && friendRequests.length <=1 && anaDiscussionStatus && categoryId  && myCreatures && myCreatures?.length>=0 && currentPath=="/[language]/[categoryId]/[categoryName]"  && ( anaDiscussionStatus.doubleCardFriendsMessage !== true) ) {
            if ( hasDuplicateCreatureId(myCreatures) ) {
                doubleCardFriendsMessage(addMessageWithId,t, character?.translationKey)
                updateAnaDiscussionStatus("SET_DOUBLE_CARD_FRIENDS_MESSAGE", true)                     
            }
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, user, friendRequests, anaDiscussionStatus, categoryId])

    useEffect(() => {
        if ( process.env.NODE_ENV==="development" ) {
            console.log("anaDiscussionStatus", anaDiscussionStatus)
        }
        if ( (defaultCharacter == 0 || defaultCharacter ) && isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && messages.length === 0 && ( anaDiscussionStatus.firstMessage !== true)){                        
            
            if ( !inAppBrowsing ) {
                firstHello(addMessageWithId,t, character?.translationKey)
                updateAnaDiscussionStatus("SET_FIRST_MESSAGE", true)         
                addOrUpdateActionHistory("welcome",1)
            }else{
                firstHelloInAppBrowsing(addMessageWithId,t,<Button onClick={()=>{
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText("gooblies.com")
                        .then(() => {
                            markMessageAsTreated("firstHelloInAppBrowsing")
                            //console.log('Texte copié avec succès !');
                        })
                        .catch((err) => {
                            markMessageAsTreated("firstHelloInAppBrowsing")
                        })
                    }
                }} colorScheme="linkedin">{t("discussion.copyUrl")}</Button>)
                updateAnaDiscussionStatus("SET_FIRST_MESSAGE", true)
                addOrUpdateActionHistory("welcome",1)
            }
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, defaultCharacter])


    useEffect(() => {
        if ( process.env.NODE_ENV==="development" ) {
            console.log("anaDiscussionStatus", anaDiscussionStatus)
        }
        if ( (defaultCharacter == 0 || defaultCharacter ) && isReadyForAna && !isAuthLoading && !isGlobalDataUserLoading && anaDiscussionStatus && messages.length === 0 && ( anaDiscussionStatus.firstMessage == true)){                        
            if ( !checkActionHistory("welcome") ){   
                if ( isRaining ) {
                    rainingHello(addMessageWithId,t, <Button onClick={()=>{
                        addFood(10)
                        markMessageAsTreated("otherHello")
                    }} colorScheme="linkedin">{t("discussion.obtainFood")}</Button>, character?.translationKey)
                }else{
                    otherHello(addMessageWithId,t, <Button onClick={()=>{
                        addFood(10)
                        markMessageAsTreated("otherHello")
                    }} colorScheme="linkedin">{t("discussion.obtainFood")}</Button>, character?.translationKey)
                }                            
                addOrUpdateActionHistory("welcome",1)
            }
        }
    },[isReadyForAna, isAuthLoading, isGlobalDataUserLoading, anaDiscussionStatus, defaultCharacter])

    useEffect(()=>{
        if ( showChallengeIndexes && showChallengeIndexes.length > 0) {
            onOpen()
        }
    },[showChallengeIndexes])

    return (
        <> 
            <Flex w="auto" justifyContent="flex-end" pb={1} zIndex={4} position="absolute" right={4} bottom={14}>               
                <Box
                    display={(showDiscussion || numberOfUnreadMessages > 0 )? "flex" : "none"}
                    bg="gray.100"
                    color="black"
                    px={2}
                    pt={2}
                    flexDir={"column"}
                    borderRadius={"md"}
                    className="chat-bubble"
                    position="relative"
                    justifyContent={"end"}
                    boxShadow='dark-lg'
                    gap={0}
                    css={css`animation: ${zoomAndShake} 0.3s ease-out;`}
                    maxW={"300px"}
                    maxH={"400px"}
                    zIndex={4}
                >
                    {showDiscussion && <CloseButton size="sm" background="gray.100" borderRadius="50%" position="absolute" right={-2} top={-2} onClick={() => setShowDiscussion(false)} />}
                    {showDiscussion && <VStack>
                        <Avatar boxShadow='lg' size="md" src={voiceTalking?character?.imageTalking:character?.image} icon={undefined}/>
                        <Text>{character?.name}</Text>
                    </VStack>}
                    <VStack gap={2} alignItems={"end"} pl={showDiscussion?8:0} overflowY="auto">
                    {(showDiscussion || numberOfUnreadMessages) && messages && messages.map((message) => {
                        if ( ((showDiscussion || (message.status=="unread" && message.timestamp <= Date.now() ))  ))
                        return(
                        <div key={message.id} >
                            <HStack gap={1}>
                                <Flex   
                                    direction={"column"}
                                    
                                    bg={message.status=="unread"?"blue.100":"gray.200"}                    
                                    minW="100px"
                                    maxW="350px"
                                    p={2}
                                    borderRadius={"md"}
                                    zIndex={2}
                                >
                                    <Text fontSize="sm">{message.message}</Text>
                                    {user &&<TextToSpeechButton
                                        voiceId={character?.voiceId as string}
                                        text={message.message}
                                        voiceSettings={{
                                            stability: 0,
                                            similarity_boost: 0,
                                        }}
                                        setVoiceTalking={setVoiceTalking}
                                        forceStopTalking={forceStopTalking}
                                        setForceStopTalking={setForceStopTalking}
                                        autoPlay={ (config && config.allowAutoVocal && (!voiceTalking && (message.status=="unread" && message.timestamp <= Date.now() )))?true:false} 
                                    />}                              
                                    {message.action && message.status!="treated" && <>{message.action}</>}
                                </Flex>
                                {showDiscussion && <Avatar bottom={1} alignSelf="self-end" size="2xs" src={character?.image}  icon={undefined}/>}
                            </HStack>
                        </div>
                    )})}
                     <div style={{height:0}} ref={conversationScrollBottom}></div>
                    </VStack>
                </Box>
                <VStack alignSelf="self-end">
                    <Box
                        position="relative"
                        border="3px solid white"
                        borderRadius={"50%"}
                        mx={3}
                        mb={2}
                        shadow={"dark-lg"}
                        marginInlineEnd={0}
                        p={2}
                        bg="#ffffff5c"
                        backdropFilter="blur(10px)"
                        onClick={onOpen}
                    >
                        <FaRegCalendarAlt style={{width:"32px", height:"32px"}}/>
                        {totalChallenges > 0 && challengesWin !== totalChallenges && <Badge className="pulse-red" position="absolute" background="red" color="white" bottom="-2" right="-2">{challengesWin}/{totalChallenges}</Badge>}
                        {totalChallenges > 0 && challengesWin == totalChallenges && <Badge className="pulse-red" position="absolute" variant='solid' colorScheme="whatsapp" bottom="-2" right="-2" borderRadius={"50%"} p={1}><FaCheck /></Badge>}
                    </Box>
                    <Box                        
                        position="relative"
                        border="3px solid white"
                        borderRadius={"50%"}
                        mx={3}
                        onClick={() => setShowDiscussion(!showDiscussion)}
                        shadow={"dark-lg"}
                        marginInlineEnd={0}
                    >
                        <Avatar boxShadow='lg' size="md" src={voiceTalking?character?.imageTalking:character?.image}  icon={undefined} />
                        {numberOfUnreadMessages >0 && <Badge className="pulse-red" position="absolute" background="red" color="white" bottom="-2" right="-2">{numberOfUnreadMessages}</Badge>}
                    </Box>
                </VStack>
            </Flex>
            <DailyChallengeModal isOpen={isOpen} onClose={onCloseChallenge} autoHide={showChallengeIndexes && showChallengeIndexes.length > 0} indexesToAnimate={showChallengeIndexes}/>
        </>
    )
}