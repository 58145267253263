import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useRouteChange = (onRouteChange:any) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url:any) => {
      onRouteChange(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // Cleanup event listener on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, onRouteChange]);
};

export default useRouteChange;