import { VStack, Image, Avatar, PortalManager, Button, Card, Portal, CardBody, HStack, CircularProgress, CircularProgressLabel, Text, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, useDisclosure, Stack, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, SkeletonCircle, Link, Badge, PopoverTrigger, PopoverContent, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverArrow, Popover, Skeleton } from "@chakra-ui/react";
import { MdArrowBackIosNew, MdCreate, MdMenu, MdPerson } from "react-icons/md";
import ScoreBoard from "./scoreBoard";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import AccountButton from "./AccountButton";
import {IoNotifications} from 'react-icons/io5'
import NextLink from "next/link";
import { GiCupcake, GiTwoCoins } from "react-icons/gi";
import { useRouter } from "next/router";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import MenuDrawer from "./menuDrawer";
import { ConfirmQuitGame } from "@/app/context/global/layout/modal/confirmQuitGame";
import {FaArrowsRotate} from 'react-icons/fa6'
import { getUserFromFriendRequests } from "@/app/helpers/getUserFromFriendRequests";
import { FriendsContext } from "@/app/context/friends/friendsContext";
import { getCreatureDetailsHelper } from "@/app/helpers/getCreatureDetailsHelper";
import {FaExchangeAlt,FaCheckCircle} from 'react-icons/fa'
import {MdCancel} from 'react-icons/md'
import { set } from "lodash";
import { TradeRequestType } from "@/app/types/tradeRequestType";
import { useTranslation } from "next-i18next";
import { FirebaseContext } from "@/app/context/firebase/firebaseContext";
import { NotificationContext } from "@/app/context/notification/notificationContext";

export default function TradeRequest() {
    const { t } = useTranslation();
    const {
        isAuthLoading,
        user,
        cloudStorage,
        sendNotificationPush
    } = useContext(AuthenticationContext)
    
    const {
        isBrowsingFromGame,
        language,
        isDesktop
    } = useContext(GlobalDataContext)

    const {
        userState,
        tradeRequests,
        addOrUpdateActionHistory
    } = useContext(GlobalDataUserContext)

    const {
        friendRequestWithUser
    }= useContext(FriendsContext)

    const {
        logAnalyticsEvent
    } = useContext(FirebaseContext)

    const {
        addError
    } = useContext(NotificationContext)

    const [numberOfTradeRequests, setNumberOfTradeRequests] = useState<number>(0)
    const [isBusy, setIsBusy] = useState<boolean>(false)

    useEffect(()=>{
        if(tradeRequests){
            setNumberOfTradeRequests(tradeRequests.filter((tradeRequest)=>tradeRequest.receiverId==user?.uid && tradeRequest.status==="pending").length)
        }else{
            setNumberOfTradeRequests(0)
        }
    },[tradeRequests])
    
    const approveTradeRequest = async(tradeRequest:TradeRequestType) => {
        setIsBusy(true)
        // Approve trade request
        cloudStorage?.switchCreature(tradeRequest).then(async(result)=>{
            if ( result ) {
                await sendNotificationPush({
                    userId : tradeRequest.senderId, 
                    title: t("notifications.title"), 
                    body: t("notifications.tradeRequestApproved")
                })
                addError(t("tradeRequest.tradeRequestApproved"), "info")
                addOrUpdateActionHistory("trade",1)
                logAnalyticsEvent("tradeRequestApproved",{})
            }else{
                addError(t("tradeRequest.tradeRequestError"), "error")
            }
            setIsBusy(false)
        })
    }

    const rejectTradeRequest = async(tradeRequest:TradeRequestType) => {
        setIsBusy(true)
        // Reject trade request

        cloudStorage?.refuseTradeRequest(tradeRequest.id).then((result)=>{
            if ( result ) {
                addError(t("tradeRequest.tradeRequestRejected"), "info")
                logAnalyticsEvent("tradeRequestRejected",{})
            }else{
                addError(t("tradeRequest.tradeRequestError"), "error")
            }
            setIsBusy(false)
        })
    }

    return (
        <>
        {!isAuthLoading && numberOfTradeRequests > 0 && <Popover>
                <PopoverTrigger>
                    <Button
                        display={"flex"}
                        background={"transparent!important"}
                        p={1}
                        pr={0}
                        m={0}
                        color="white"
                        fontSize={"1.0em"}
                        position="relative"
                    >
                        <FaArrowsRotate />
                        <Badge position="absolute" bottom={0} className="pulse-red" right={0} color="white" bg='red'>{numberOfTradeRequests}</Badge>
                    </Button>  
                </PopoverTrigger>
                <Box zIndex={100}>
                <Portal>
                <PopoverContent color="black" >
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>{t("tradeRequest.tradeSuggestion")}</PopoverHeader>
                    <PopoverBody>
                        <Skeleton isLoaded={!isBusy}>
                        {tradeRequests.filter((tradeRequest)=>tradeRequest.receiverId==user?.uid && tradeRequest.status==="pending").map((tradeRequest)=>{
                            return <div key={tradeRequest.id}>
                            <Flex direction="row" alignItems={"center"}>
                                <Avatar size="2xs" src={getUserFromFriendRequests(friendRequestWithUser,tradeRequest.senderId)?.photoUrl}  icon={undefined} name={getUserFromFriendRequests(friendRequestWithUser,tradeRequest.senderId)?.name} />
                                <Text pl={1} textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}>{getUserFromFriendRequests(friendRequestWithUser,tradeRequest.senderId)?.name}</Text>
                            </Flex>
                            <HStack  py={1}>
                                <Flex position="relative" h="125px" borderRadius={10} overflow="hidden">
                                    <Image borderRadius={10} src={getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.url} alt="monster"/>
                                    <Flex 
                                        position="absolute"
                                        bottom={1}
                                        w="full"
                                        justifyContent={"center"}
                                    >
                                        <Text 
                                            background={getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.type=="LEGENDARY"? "#eba628":(getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.type=="AQUATIC"? "#3797d1":"#00000099")} 
                                            px={3} 
                                            py={1} 
                                            fontSize={"0.7em"} 
                                            borderRadius={10} 
                                            fontWeight="bold" 
                                            color={getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.type=="LEGENDARY"?"white":"white"}
                                            lineHeight={"normal"}
                                        >
                                            {getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.name.toUpperCase()}
                                            {getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.level && <Badge minW="21px" ml={2} p={1} borderRadius={"10"}>{getCreatureDetailsHelper(tradeRequest.senderCreatureId)?.level}</Badge>}
                                        </Text>   
                                    </Flex>
                                </Flex>
                                <Box><FaExchangeAlt/></Box>
                                <Flex position="relative" h="125px" borderRadius={10} overflow="hidden">
                                    <Image borderRadius={10} src={getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.url} alt="monster"/>
                                    <Flex 
                                        position="absolute"
                                        bottom={1}
                                        w="full"
                                        justifyContent={"center"}
                                    >
                                        <Text 
                                            background={getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.type=="LEGENDARY"? "#eba628":(getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.type=="AQUATIC"? "#3797d1":"#00000099")} 
                                            px={3} 
                                            py={1} 
                                            fontSize={"0.7em"} 
                                            borderRadius={10} 
                                            fontWeight="bold" 
                                            color={getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.type=="LEGENDARY"?"white":"white"}
                                            lineHeight={"normal"}
                                        >
                                            {getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.name.toUpperCase()}
                                            {getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.level && <Badge minW="21px" ml={2} p={1} borderRadius={"10"}>{getCreatureDetailsHelper(tradeRequest.receiverCreatureId)?.level}</Badge>}
                                        </Text>   
                                    </Flex>
                                </Flex>
                                <VStack gap={5}>
                                    <Button size="sm" bg="transparent!" color="green" onClick={()=>{approveTradeRequest(tradeRequest)}}><FaCheckCircle fontSize={"2.5em"}/></Button>
                                    <Button size="sm" bg="transparent!" color="red" onClick={()=>{rejectTradeRequest(tradeRequest)}}><MdCancel fontSize={"2.5em"}/></Button>
                                </VStack>
                            </HStack>
                            </div>
                        })}
                        </Skeleton>
                    </PopoverBody>
                </PopoverContent>
                </Portal>
                </Box>
            </Popover>             
        }
        </>
    )
}