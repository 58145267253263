// AudioStream.tsx
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { FaExclamation, FaPlay, FaStop } from "react-icons/fa";
import { Box, Button, Spinner } from "@chakra-ui/react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { StreamToPlayType } from "@/app/types/streamToPlayType";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";

interface VoiceSettings {
  stability: number;
  similarity_boost: number;
}

interface AudioStreamProps {
  voiceId: string;
  text: string;
  voiceSettings: VoiceSettings;
  setVoiceTalking: Dispatch<React.SetStateAction<boolean>>;
  forceStopTalking: boolean
  setForceStopTalking: Dispatch<React.SetStateAction<boolean>>;
  autoPlay: boolean
  hide?: boolean
}

const TextToSpeechButton: React.FC<AudioStreamProps> = ({
  voiceId,
  text,
  voiceSettings,
  setVoiceTalking,
  forceStopTalking,
  setForceStopTalking,
  autoPlay,
  hide=true
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [isPlaying, setIsPlaying] = useState(false)
  const [audio, setAudio]=useState<HTMLAudioElement|null>(null)
  const [streamToPlay, setStreamToPlay] = useState<StreamToPlayType[]|undefined>()
  const [actualStreamToPlay, setActualStreamToPlay] = useState(0)

  const {
        user
    } = useContext(AuthenticationContext)

    const {
        language,
        categoryId
    } = useContext(GlobalDataContext)

    const {
      config
    }=useContext(GlobalDataUserContext)

  const getStream = async (text:string)=>{
      let cacheResponse
      // CALL VIA BACK BUT DONT WORK
      const BASE_URL = process.env.NEXT_PUBLIC_API_URL_V2

      const headers = {
          "Content-type": "application/json",
          'Authorization' : "Bearer " + await user?.getIdToken(),
          accept: "audio/mpeg"
      };

      const requestBody = {
          textToSpeech: text,
      };

      const response = await fetch(`${BASE_URL}${language}/voice/${voiceId}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody)
      });

      if (response.status === 200 ) {
          const base64String = await response.text()
          const binaryString = atob(base64String);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes.buffer], {type: 'audio/mpeg'});

          cacheResponse = blob
          if ( process.env.NODE_ENV==="development" ) {
            console.log("reponse elevenlab",cacheResponse)
          }
          return cacheResponse
      }
      return false
  }

  const updateStreamToPlay = (id:number, stream:Blob|null, error:boolean)=> {
    setStreamToPlay( (streamToPlayState) => {      
        return streamToPlayState?.map(item=> item.id == id ? {...item, stream:stream, error: error} : item)
    })
  }

  useEffect(()=> {
    if (!isPlaying && audio) {
        let finished = false
        if ( process.env.NODE_ENV==="development" ) {
          console.log("streamToPlay:actualStreamToPlay", actualStreamToPlay, streamToPlay)
        }
        streamToPlay?.forEach(item=> {
            if ( item.id == actualStreamToPlay && streamToPlay.length <= actualStreamToPlay+1) {
              if ( process.env.NODE_ENV==="development" ) {
                console.log("streamToPlay:actualStreamToPlay+1", actualStreamToPlay)
              }
              if (item.stream) {
                  finished = true
              }
            }
            if ( item.id==(actualStreamToPlay +1 )) {
                if ( process.env.NODE_ENV==="development" ) {
                  console.log("streamToPlay:actualStreamToPlay+1", actualStreamToPlay+1)
                }
                
                if (item.stream){                  
                  
                    if ( !item.error ){
                        try{
                            if ( process.env.NODE_ENV==="development" ) {
                              console.log("streamToPlay:streamFromNextstreamToPLay", item.text, actualStreamToPlay, item.id)
                            }
                            audio.src=URL.createObjectURL(item.stream)
                            audio.muted=true
                            //setForceStopTalking(false)
                            audio.play();
                        }catch(e:any){
                            if ( process.env.NODE_ENV==="development" ) {
                              console.log("streamToPlay:error streaming", e.message)
                            }
                        }
                    }                    
                    streamToPlay.forEach(item2=> {
                        if ( item2.id == actualStreamToPlay + 2) {  
                                                
                            if ( process.env.NODE_ENV==="development" ) {
                              console.log("streamToPlay:actualStreamToPlay+2", actualStreamToPlay+2)
                            }
                            getStream(item2.text).then (result => {
                                if ( result ) {
                                    updateStreamToPlay(item2.id, result, false )
                                }else{
                                    updateStreamToPlay(item2.id, null, true )
                                }
                            })                            
                        }
                    })
                    setActualStreamToPlay(actualStreamToPlay+1)
                }
            }
        })
        if ( finished && !isPlaying) {
          if ( process.env.NODE_ENV==="development" ) {
            console.log("streamToPlay:finished")
          }
          setStreamToPlay(undefined)
          setActualStreamToPlay(0)
          setVoiceTalking(false)
        }
    }
  }, [isPlaying, streamToPlay])

  const startStreaming = async () => {
    if ( process.env.NODE_ENV==="development" ) {
      console.log("startstreaming")
    }
    setIsLoading(true);
    setIsError("");
    setForceStopTalking(true)
    try {
       
        const texts = text.split(".")
        if ( process.env.NODE_ENV==="development" ) {
          console.log("streamToPlay:WTF texts", texts)
        }
        const stream = await getStream(texts[0]+".")

        const streamToPlayState:StreamToPlayType[] = [] 
        let i = 0
        texts.forEach((item, index)=> {
            if (item.trim()) {                
              if ( process.env.NODE_ENV==="development" ) {
                console.log("streamToPlay:WTF INDEX", i)
              }
              streamToPlayState.push({
                id: i,
                text: item.trim() +".",
                stream: (i == 0 && stream) ? stream: null,
                error: stream ? false: true
              } )
              i++
            } 
        })
        if ( process.env.NODE_ENV==="development" ) {
          console.log("streamToPlay:streamToPlayState", streamToPlayState)
        }
        if ( streamToPlayState && streamToPlayState.length && audio && stream ) {
          setActualStreamToPlay(-1)
          setStreamToPlay(streamToPlayState)
        }

       /* if (cacheResponse&& audio) {
            audio.src=URL.createObjectURL(cacheResponse)
            setForceStopTalking(false)
            audio.play();
        
        } else {
            setIsError("Error: Unable to stream audio.");
            setIsLoading(false);
        }*/
    } catch (error:any) {
      if ( process.env.NODE_ENV==="development" ) {
          console.log("error streaming", error.message)
      }
      setIsError("Error: Unable to stream audio.");
      setIsLoading(false);
      setVoiceTalking(false); 
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    if ( forceStopTalking) {
      audio?.pause()
      setActualStreamToPlay(0)
      setStreamToPlay(undefined)
      setVoiceTalking(false)
      setForceStopTalking(false)
    }
  },[forceStopTalking])

  useEffect(()=>{
    if (audio){
        const playing=()=>{
          if ( process.env.NODE_ENV==="development" ) {
              console.log("playing")
          }
          audio.muted=false
            setIsPlaying(true)
            setVoiceTalking(true)
        }
        const stopPlaying=()=>{
          if ( process.env.NODE_ENV==="development" ) {
            console.log("stop playing")
          }
            setIsPlaying(false)
            //setVoiceTalking(false)
        }

        if (autoPlay) {
            setForceStopTalking(true)
            startStreaming()
        }
        audio.addEventListener("ended", ()=>{stopPlaying()})
        audio.addEventListener("pause", ()=>{stopPlaying()})
        audio.addEventListener("play", ()=>{playing()})

        return () => {
          if ( process.env.NODE_ENV==="development" ) {
            console.log("stop playing sound")
          }
            //setCacheAudio(null)
            audio.pause()
            audio.removeEventListener("ended", ()=>{stopPlaying()})
            audio.removeEventListener("play", ()=>{playing()})
            audio.removeEventListener("pause", ()=>{stopPlaying()})
        }
    }
  },[audio])

  useEffect(()=>{
    setAudio(new Audio())
    console.log("audio created", autoPlay)
    return () => {
      if (audio) {
        audio.pause();  // Arrête l'audio
        setVoiceTalking(false);  // Assure-toi que l'état est réinitialisé
        if ( process.env.NODE_ENV==="development" ) {
          console.log("Composant AudioStream démonté, audio arrêté");
        }
      }
    };
  },[])

  const stopPlaying=()=>{
    if (audio){
        audio.pause()
        setActualStreamToPlay(0)
        setStreamToPlay(undefined)
        setVoiceTalking(false)
    }
  }

  return (
    <>
      <Button       
        py={hide && (config && config.allowAutoVocal)?0:2}
        display={"flex"}
        h={hide && (config && config.allowAutoVocal)?"0!important":"auto"}
        overflow="hidden"
        w="full"
        flexDir={"row"}
        alignSelf={"start"}
        disabled={isLoading}
        bg={"white!important"}
        color={"black!important"}
        my={"0"}
        onClick={()=>{
          if ( !isError && !isLoading){
            if ( isPlaying){
              stopPlaying()
            }else{
              setForceStopTalking(true)
              startStreaming()
            }
          }
        }}
        >
        {isPlaying && !isLoading && !isError && <FaStop/>}
        {!isPlaying && !isLoading && !isError && <FaPlay/>}
        {isLoading && !isError && <Spinner />}
        {isError && <FaExclamation color={"red"}/>}
        <Box paddingLeft="10px" className={(!isPlaying?"stop":"" ) + " boxContainer"}>
            <div className="box box1"></div>
            <div className="box box4"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box5"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box2"></div>
            <div className="box box5"></div>
            <div className="box box1"></div>
        </Box>
      </Button>
    </>
  );
};

export default TextToSpeechButton;