

export const numberFormatterHelper = (num:number, digits:number) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const distanceFormatterHelper = (num:number, digits:number) => {
    const lookup = [
        { value: 1, symbol: " m" },
        { value: 1e3, symbol: " km" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const timeFormatterHelper = (num:number, digits:number) => {
    const lookup = [
        { value: 1, symbol: "" },       // 1 seconde
        { value: 60, symbol: ":" },      // 60 secondes = 1 minute
        { value: 3600, symbol: ":" },    // 3600 secondes = 1 heure
    ];

    // Calculer les heures, minutes et secondes
    const hours = Math.floor(num / 3600);
    const minutes = Math.floor((num % 3600) / 60);
    const seconds = Math.floor(num % 60);

    // Créer un affichage au format hh:mm:ss
    const padWithZero = (number:number) => number < 10 ? `0${number}` : number.toFixed(digits);

    const formattedTime = `${hours > 0 ? `${padWithZero(hours)}:` : ""}${minutes > 0 || hours > 0 ? `${padWithZero(minutes)}:` : ""}${padWithZero(seconds)}`;

    return formattedTime.trim(); 
}