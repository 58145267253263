import { FocusableElement } from "@chakra-ui/dom-utils";
import { AlertDialog, AlertDialogContent } from "@chakra-ui/modal";
import { AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React, { MutableRefObject } from "react";

type Props = {
    isOpen: boolean
    onClose: ()=>void
    onConfirmQuit: ()=>void
}

export const ConfirmQuitGame = ({isOpen, onClose, onConfirmQuit}:Props) => {
    const cancelRef: MutableRefObject<FocusableElement | undefined> = React.useRef()
    const { t } = useTranslation();

    const onDelete = async()=> {
        
    }

    return(
        <AlertDialog
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef as MutableRefObject<any>}
            onClose={()=>onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent
                >
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    {t("menu.confirmQuit.question")}
                    </AlertDialogHeader>

                    <AlertDialogFooter>
                        <Button
                            size="sm"
                            ref={cancelRef as MutableRefObject<any>}
                            onClick={onClose}>
                            {t("menu.confirmQuit.no")}
                        </Button>
                        <Button size="sm" colorScheme='orange' onClick={onConfirmQuit} ml={3}>
                            {t("menu.confirmQuit.yes")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
