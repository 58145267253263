'use client'
import { Button, Text, Drawer, HStack, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, Link, List, ListItem, Flex, Menu, MenuButton, Box, MenuList, MenuGroup, MenuItem, DrawerFooter, Alert, AlertIcon } from "@chakra-ui/react";
import { MdLanguage, MdMenu, MdOutlineCheck, MdOutlineHome } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { useTranslation } from "next-i18next";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import NextLink from "next/link";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { FaFacebook, FaSpaghettiMonsterFlying } from "react-icons/fa6";
import useRouteChange from "@/app/hooks/usseRouteChange";
import { v4 } from "uuid";
import { TfiTarget } from "react-icons/tfi";
import {TbDiamondFilled, TbPolygon} from "react-icons/tb";
import {FaEdit, FaShoppingCart} from "react-icons/fa";
import { ZoneType } from "@/app/types/zoneType";
import { useRouter } from "next/router";
import { availableLanguageConst } from "@/app/const/availableLanguageConst";
import ReactCountryFlag from "react-country-flag"
import { buildArtifactUrl, buildChaseUrl, buildEggUrl, buildGameUrl, buildQuestUrl, buildShoppingUrl, buildZoneUrl } from "@/app/helpers/buildUrlHelper";
import { FaEgg, FaUser } from "react-icons/fa";
import {GiPathDistance} from "react-icons/gi";
import {BiWorld} from "react-icons/bi";
import { RiInstagramFill } from "react-icons/ri";

export default function MenuDrawer() {
    const router = useRouter();
    const { t } = useTranslation();
    const { isOpen:isOpenDrawer, onOpen:onOpenDrawer, onClose:onCloseDrawer } = useDisclosure()
    const {isOpen: isOpenLogout, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure()
    const { isOpen: isOpenDeleteAccount, onOpen: onOpenDeleteAccount, onClose:onCloseDeleteAccount } = useDisclosure()
    const [chaseId, setChaseId] = useState<string>("")
    const [showMore, setShowMore] = useState(false);
    const [myOwnZones, setMyOwnZones] = useState<ZoneType[]>([])

    const {
        language,
        isDesktop
    } = useContext(GlobalDataContext)

    const {
        user,
        isAdmin,
        setShowLoginModal,
        setLogOut,
        isAuthLoading,
        updateProfileName,
        //cloudStorage        
    } = useContext(AuthenticationContext)

    const {
        score,
        isGlobalDataUserLoading,
        config,
        setConfig,
        myZones,
        userInfo,
        allPlayersPositions
        //isNewsletterFromMailchimp,
        //setIsNewsletterFromMailchimp,
        //isLoadingMailchimp
    } = useContext(GlobalDataUserContext)

    useEffect(()=>{
        setMyOwnZones(myZones.filter(zone=>zone.ownerId===user?.uid))
    },[myZones])

    const displayedZones = showMore ? myOwnZones : myOwnZones.slice(0, 5);

    useEffect(()=>{
        setChaseId(v4())
    },[])
    
    const handleRouteChange = () => {
        if (isOpenDrawer) {
          onCloseDrawer(); // Fermer le drawer lorsque la route change
        }
      };
    
      useRouteChange(handleRouteChange);
    
    const currentPath = router.pathname;

    const generateLink = (lang: string) => {
        // Remplace le paramètre [language] par la nouvelle langue
        const newPath = currentPath.replace(`[language]`, lang);
        
          // Créer l'URL complète avec le query string
            const fullPath = {
                pathname: newPath,
                query: { ...router.query, language: lang },
            };
            
            // Crée une URL complète sous forme de chaîne
            const url = `${newPath}?${new URLSearchParams(fullPath.query).toString()}`;
        
            return url;
      };
    
      const allowCreateZone= ()=>{
        if( userInfo && userInfo.isAdmin){
            return true
        }
        return false
      }

    return (
        <>
            <> 
                <Button
                        display={{base:"flex", xl:"none"}}
                        background={"transparent!important"}
                        p={1}
                        m={0}
                        color="white"
                        fontSize={"1.5em"}
                        onClick={onOpenDrawer}
                    >
                            <MdMenu />
                    </Button>    
                <Drawer
                    isOpen={isOpenDrawer }
                    placement='left'
                    onClose={onCloseDrawer}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader pt={2} pb={0} px={4}>{t("menu.title")}</DrawerHeader>
                        <DrawerBody px={2} >
                            <List>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={"/"+language} as={NextLink} shallow passHref> <MdOutlineHome/> <Text pl={2}>{t("menu.home")}</Text></Link>
                                </ListItem>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildGameUrl(language,1)} as={NextLink} shallow passHref> <FaSpaghettiMonsterFlying/> <Text pl={2}>{t("menu.myGooblies")}</Text></Link>
                                </ListItem>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildEggUrl(language,1)} as={NextLink} shallow passHref> <FaEgg /> <Text pl={2}>{t("menu.myEggs")}</Text></Link>
                                </ListItem>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildArtifactUrl(language)} as={NextLink} shallow passHref> <TbDiamondFilled /> <Text pl={2}>{t("menu.artifacts")}</Text></Link>
                                </ListItem>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildChaseUrl(language,1,chaseId)} as={NextLink} shallow passHref> <BiWorld /> <Text pl={2}>{t("menu.goExplore")}</Text></Link>
                                </ListItem>
                                <ListItem px={2}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildQuestUrl(language,1)} as={NextLink} shallow passHref> <GiPathDistance /> <Text pl={2}>{t("menu.goQuest")}</Text></Link>
                                </ListItem>                                
                                <ListItem px={2} borderRadius={10} bg="red" color="white">
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildShoppingUrl(language)} as={NextLink} shallow passHref> <FaShoppingCart/> <Text pl={2}>{t("menu.shopping")}</Text></Link>
                                </ListItem>
                                <ListItem px={2} pt={2} fontSize={"sm"} fontWeight={"bold"}>{t("menu.myZones")} {user && <small>({myOwnZones.length}/{myOwnZones.length>3?myOwnZones.length:3})</small>}</ListItem>
                                {/* Affichage des zones avec Show More/Show Less */}
                                {user && myOwnZones && displayedZones.map((zone:any, index) => (
                                <ListItem px={2} key={index}>
                                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildZoneUrl(language, zone.id)} as={NextLink} shallow passHref>
                                    <TbPolygon /> <Text pl={2}>{zone.name}</Text>
                                    </Link>
                                </ListItem>
                                ))}
                                {(user && (!myOwnZones || myOwnZones.length==0 ))&& <ListItem px={2}>
                                    {t("menu.noZones")}
                                    </ListItem>    
                                }
                                {!user && <ListItem px={2}>
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        {t("menu.noZonesNotConnected")}
                                    </Alert>                                        
                                    </ListItem>}
                                {/* Bouton Show More/Show Less */}
                                {myOwnZones.length > 5 && (
                                    <ListItem px={2} textAlign="center" >
                                        <Button
                                        onClick={() => setShowMore(!showMore)}
                                        variant="ghost"
                                        size="xs"
                                        colorScheme="blue"
                                        >
                                        <Text pl={2}>{showMore ? t("menu.showLess") : t("menu.showMore")}</Text>
                                        </Button>
                                    </ListItem>
                                )}

                                {user && <ListItem px={2}>
                                    {(myOwnZones.length < 3 || allowCreateZone() )&&
                                        <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildZoneUrl(language, '0')} as={NextLink} shallow passHref> <FaEdit/> <Text pl={2}>{t("menu.createZone")}</Text></Link>}
                                    {myOwnZones.length >= 3 && !allowCreateZone() &&
                                        <Box py={2} alignItems="center" display="flex" flex={"row"} opacity="0.3"><FaEdit/>  <Text pl={2}>{t("menu.createZone")}</Text></Box>
                                    }
                                </ListItem>}
                                {user && userInfo && userInfo.isAdmin && <>
                                    <ListItem px={2} pt={2} fontSize={"sm"} fontWeight={"bold"}>Mes contenus</ListItem>
                                    <ListItem px={2}>
                                        <Link py={2} alignItems="center" display="flex" flex={"row"} href={"/fr/content/0"} as={NextLink} shallow passHref> <GiPathDistance /> <Text pl={2}>Créer du contenu</Text></Link>
                                    </ListItem> 
                                </>}
                            </List>
                        </DrawerBody>
                        <DrawerFooter py={0} px={2} justifyContent={"space-around"}>
                            <Flex alignItems="center" w="full" placeContent={"space-between"}>
                                <Text opacity="0.6" display="flex" alignItems="center" fontSize="xs">v {process.env.NEXT_PUBLIC_VERSION} {userInfo && userInfo.isAdmin && allPlayersPositions && <> | <FaUser style={{marginLeft:5, marginRight:2}} fontSize={"0.7em"} /> {allPlayersPositions.length+1}</>}</Text>                                
                                <HStack>
                                    <Text>
                                        <Link target="_blank" display="inline-flex" p={2} px={2} fontSize={"1.25em"} href={process.env.NEXT_PUBLIC_FACEBOOK_URL}><FaFacebook /></Link>
                                        <Link target="_blank" display="inline-flex" p={2} px={2} pr={0} fontSize={"1.25em"} href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}><RiInstagramFill /></Link>
                                    </Text>
                                    <Menu
                                        placement='top'
                                        strategy={"fixed"}
                                    >

                                        <MenuButton
                                            display={"flex"}
                                            background={"transparent!important"}
                                            as={Button}
                                            p={0}
                                            px={2}
                                            m={0}         
                                            position={"relative"}    
                                        >   
                                            {t("home.language") as string}                                         
                                                <ReactCountryFlag       
                                                    style={{paddingLeft:"10px"}}                                          
                                                    countryCode={language=="en"?"gb":language} 
                                                />
                                        </MenuButton>
                                        <MenuList shadow={"xl"}>
                                            <MenuGroup
                                                m={0}
                                                px={3}
                                                title={t("home.selectLanguage") as string}
                                            >
                                                {availableLanguageConst.map((item, index) => (
                                                    
                                                    <Link
                                                        key={index}
                                                        display={"flex"}
                                                        w={"full"}
                                                        as={NextLink}
                                                        href={generateLink(item.code)}
                                                        p={0}
                                                        position={"relative"}
                                                    >
                                                        <MenuItem>
                                                            <Box
                                                                paddingRight={"10px"}
                                                            >
                                                                <ReactCountryFlag 
                                                                    style={{
                                                                        fontSize: '1em',
                                                                        lineHeight: '1em',
                                                                    }}
                                                                    countryCode={item.code=="en"?"gb":item.code} 
                                                                    
                                                                />
                                                            </Box>
                                                            {item.description}
                                                            {(language == item.code) && <Box
                                                                position={"absolute"}
                                                                right={0}
                                                                paddingRight={"10px"}
                                                            >                                                
                                                                <MdOutlineCheck/>
                                                            </Box>
                                                            }
                                                        </MenuItem>
                                                    </Link>
                                                    
                                                ))}

                                            </MenuGroup>
                                        </MenuList>

                                    </Menu>
                                </HStack>
                            </Flex>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        </>        
    )
}