import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { getLevel, getLevelName } from "@/app/helpers/levelConversionHelper";
import { distanceFormatterHelper } from "@/app/helpers/numberFormatterHelper";
import { Flex, Text, Box, SkeletonText, Progress } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { BsAwardFill } from "react-icons/bs";

export default function ScoreBoard() {
    const { t } = useTranslation();
    const {
        userState
    }=useContext(GlobalDataUserContext)
    
    const isGlobalDataUserLoading = false
    return (
        <>
            <Flex>
                <Text>
                    {t("account.levelName")}
                </Text>
                
                <Box
                    flex={1}
                    textAlign={"right"}
                >
                    {isGlobalDataUserLoading &&
                        <SkeletonText alignSelf={"center"} noOfLines={1}/>
                    }
                    {!isGlobalDataUserLoading &&
                        <>{t("account.level."+getLevelName(userState?.points || 0))}</>
                    }
                </Box>
            </Flex>
            {isGlobalDataUserLoading &&
                <SkeletonText alignSelf={"center"} noOfLines={1}/>
            }
            {!isGlobalDataUserLoading  &&
                    <Progress value={(getLevel(userState?.points || 0))*10} size='xs' colorScheme='orange' background={"palegoldenrod"}/>
            }
            
            <Flex>
                <Box
                    alignSelf={"center"}
                >
                    <Text color="#398fc3" fontSize={"0.9em"} fontWeight={"bold"}>XP </Text>
                </Box>
                <Box
                    flex={1}
                    textAlign={"right"}
                    color="#398fc3" fontSize={"0.9em"} fontWeight={"bold"}
                >
                    {isGlobalDataUserLoading &&
                        <SkeletonText alignSelf={"center"} noOfLines={1}/>
                    }
                    {!isGlobalDataUserLoading &&
                        <>{userState?.points || 0}</>
                    }
                    
                </Box>
            </Flex>
            {/*<Flex>
                <Box
                    alignSelf={"center"}
                >
                    <Text color="#398fc3" fontSize={"0.9em"} fontWeight={"bold"}>XP </Text>
                </Box>
                <Box
                    flex={1}
                    textAlign={"right"}
                >
                    {isGlobalDataUserLoading &&
                        <SkeletonText alignSelf={"center"} noOfLines={1}/>
                    }
                    {!isGlobalDataUserLoading &&
                        <>{userState?.xp || 0}</>
                    }
                </Box>
            </Flex>*/}
            <Flex>
                <Box
                    alignSelf={"center"}
                >
                    <Text>{t("account.food")} </Text>
                </Box>
                <Box
                    flex={1}
                    textAlign={"right"}
                >
                    {isGlobalDataUserLoading &&
                        <SkeletonText alignSelf={"center"} noOfLines={1}/>
                    }
                    {!isGlobalDataUserLoading &&
                        <>{userState?.food || 0}</>
                    }
                </Box>
            </Flex>         
            <Flex>
                <Text>{t("account.kilometers")}</Text>
                <Box
                    flex={1}
                    textAlign={"right"}
                >
                    {isGlobalDataUserLoading &&
                        <SkeletonText alignSelf={"center"} noOfLines={1}/>
                    }
                    {!isGlobalDataUserLoading  &&
                        <>{distanceFormatterHelper(
                            userState?.kilometers || 0,
                            1
                        )}</>
                    }                                    
                </Box>
            </Flex>
            
        </>
    )
}