import { Divider, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { BsAwardFill } from "react-icons/bs";
import { FaSpaghettiMonsterFlying } from "react-icons/fa6";

type Props = {
    state: any
}
export const ScoreBar = ({state}:Props) => {
    const { t } = useTranslation();
    return (
   
        <HStack
            fontSize={"0.8rem"}
            fontWeight={"normal"}
            background={"#ededed40"}
            px={2}
            borderRadius={4}
            border={"1px solid #f5f5f5"}
            display={"inline-flex"}
            paddingInlineStart={0}
        >
            <Text color="#398fc3" fontSize={"0.9em"} fontWeight={"bold"} >XP </Text>
            { state &&
                <Text color="#398fc3" fontSize={"0.9em"} fontWeight={"bold"}>{state!.points || 0}</Text>
            }
            <Divider orientation='vertical' borderColor={"#0000006e"} h="10px"/>

            <FaSpaghettiMonsterFlying
                size={12}/>

            { state &&
                <Text>{state!.gooblies || 0}</Text>
            }

        </HStack>
)
}
