'use client'
import { Portal, Button, Text, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, Link, List, ListItem, Flex, Menu, MenuButton, Box, MenuList, MenuGroup, MenuItem, DrawerFooter, Alert, AlertIcon } from "@chakra-ui/react";
import { MdMenu, MdOutlineCheck, MdOutlineHome } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { useTranslation } from "next-i18next";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import NextLink from "next/link";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { FaEgg, FaSpaghettiMonsterFlying } from "react-icons/fa6";
import useRouteChange from "@/app/hooks/usseRouteChange";
import { v4 } from "uuid";
import { TfiTarget } from "react-icons/tfi";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import { buildArtifactUrl, buildChaseUrl, buildEggUrl, buildGameUrl, buildQuestUrl, buildShoppingUrl, buildZoneUrl } from "@/app/helpers/buildUrlHelper";
import { ZoneType } from "@/app/types/zoneType";
import ReactCountryFlag from "react-country-flag";
import { availableLanguageConst } from "@/app/const/availableLanguageConst";
import { useRouter } from "next/router";
import { FaEdit, FaShoppingCart } from "react-icons/fa";
import {FaUser} from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { TbDiamondFilled } from "react-icons/tb";
import {BiWorld} from "react-icons/bi";

export default function MenuContent() {
    const { t } = useTranslation();
    const router = useRouter();
    const { isOpen:isOpenDrawer, onOpen:onOpenDrawer, onClose:onCloseDrawer } = useDisclosure()
    const {isOpen: isOpenLogout, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure()
    const { isOpen: isOpenDeleteAccount, onOpen: onOpenDeleteAccount, onClose:onCloseDeleteAccount } = useDisclosure()
    const [chaseId, setChaseId] = useState<string>("")
    const [myOwnZones, setMyOwnZones] = useState<ZoneType[]>([])
    const {
        language,
        isDesktop
    } = useContext(GlobalDataContext)

    const {
        user,
        setShowLoginModal,
        setLogOut,
        isAuthLoading,
        updateProfileName,
        //cloudStorage        
    } = useContext(AuthenticationContext)

    const {
        score,
        isGlobalDataUserLoading,
        config,
        setConfig,
        myZones,
        userInfo,
        allPlayersPositions

        //isNewsletterFromMailchimp,
        //setIsNewsletterFromMailchimp,
        //isLoadingMailchimp
    } = useContext(GlobalDataUserContext)

    useEffect(()=>{
        setChaseId(v4())
    },[])
    
    useEffect(()=>{
        setMyOwnZones(myZones.filter(zone=>zone.ownerId===user?.uid))
    },[myZones])

    const currentPath = router.pathname;

    const generateLink = (lang: string) => {
        // Remplace le paramètre [language] par la nouvelle langue
        const newPath = currentPath.replace(`[language]`, lang);
        
          // Créer l'URL complète avec le query string
            const fullPath = {
                pathname: newPath,
                query: { ...router.query, language: lang },
            };
            
            // Crée une URL complète sous forme de chaîne
            const url = `${newPath}?${new URLSearchParams(fullPath.query).toString()}`;
        
            return url;
      };

    return (
        <>                
            <List height="full" overflow="auto">
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={"/"+language} as={NextLink} shallow passHref> <MdOutlineHome/> <Text pl={2}>{t("menu.home")}</Text></Link>
                </ListItem>
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildGameUrl(language,1)} as={NextLink} shallow passHref> <FaSpaghettiMonsterFlying/> <Text pl={2}>{t("menu.myGooblies")}</Text></Link>
                </ListItem>
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildEggUrl(language,1)} as={NextLink} shallow passHref> <FaEgg /> <Text pl={2}>{t("menu.myEggs")}</Text></Link>
                </ListItem>
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildArtifactUrl(language)} as={NextLink} shallow passHref> <TbDiamondFilled /> <Text pl={2}>{t("menu.artifacts")}</Text></Link>
                </ListItem>
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildChaseUrl(language,1,chaseId)} as={NextLink} shallow passHref> <BiWorld /> <Text pl={2}>{t("menu.goExplore")}</Text></Link>
                </ListItem>
                <ListItem px={2}>
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildQuestUrl(language,1)} as={NextLink} shallow passHref> <GiPathDistance /> <Text pl={2}>{t("menu.goQuest")}</Text></Link>
                </ListItem>     
                <ListItem px={2} borderRadius={10} bg="red" color="white">
                    <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildShoppingUrl(language)} as={NextLink} shallow passHref> <FaShoppingCart/> <Text pl={2}>{t("menu.shopping")}</Text></Link>
                </ListItem>
                <ListItem px={2} py={2} fontSize={"sm"} fontWeight={"bold"}>{t("menu.myZones")} {user && <small>({myOwnZones.length}/{myOwnZones.length>3?myOwnZones.length:3})</small>}</ListItem>
                {myOwnZones && myOwnZones.map((zone:any,index:number)=>(
                    <ListItem px={2} key={index}>
                        <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildZoneUrl(language, zone.id)} as={NextLink} shallow passHref> <MdOutlineHome/> <Text pl={2}>{zone.name}</Text></Link>
                    </ListItem>
                ))}
                {(user && (!myOwnZones || myOwnZones.length==0 ))&& <ListItem px={2}>
                    {t("menu.noZones")}
                    </ListItem>    
                }
                {!user && <ListItem px={2}>
                    <Alert status='warning' color="black">
                        <AlertIcon />
                        {t("menu.noZonesNotConnected")}
                    </Alert>                                        
                    </ListItem>}

                {user && <ListItem px={2}>
                    {myOwnZones.length < 3 &&
                        <Link py={2} alignItems="center" display="flex" flex={"row"} href={buildZoneUrl(language, '0')} as={NextLink} shallow passHref> <FaEdit/> <Text pl={2}>{t("menu.createZone")}</Text></Link>}
                    {myOwnZones.length >= 3 &&
                        <Box py={2} alignItems="center" display="flex" flex={"row"} opacity="0.3"><FaEdit/>  <Text pl={2}>{t("menu.createZone")}</Text></Box>
                    }
                    </ListItem>}
            </List>
            <Flex alignItems="center" w="full" placeContent={"space-between"}>
            <Text opacity="0.6" display="flex" alignItems="center" fontSize="xs">v {process.env.NEXT_PUBLIC_VERSION} {userInfo && userInfo.isAdmin && allPlayersPositions && <> | <FaUser style={{marginLeft:5, marginRight:2}} fontSize={"0.7em"} /> {allPlayersPositions.length+1}</>}</Text>                                
                <Menu
                    placement='top'
                    strategy={"fixed"}
                >
                    <MenuButton
                        display={"flex"}
                        background={"transparent!important"}
                        as={Button}
                        p={0}
                        px={2}
                        m={0}         
                        position={"relative"}    
                        color="white"
                    >   
                        {t("home.language") as string}                                         
                            <ReactCountryFlag       
                                style={{paddingLeft:"10px"}}                                          
                                countryCode={language=="en"?"gb":language} 
                            />
                    </MenuButton>
                    <Portal>
                    <MenuList shadow={"xl"} color="black">
                        <MenuGroup
                            m={0}
                            px={3}
                            title={t("home.selectLanguage") as string}
                        >
                            {availableLanguageConst.map((item, index) => (
                                
                                <Link
                                    key={index}
                                    display={"flex"}
                                    w={"full"}
                                    as={NextLink}
                                    href={generateLink(item.code)}
                                    p={0}
                                    position={"relative"}
                                >
                                    <MenuItem>
                                        <Box
                                            paddingRight={"10px"}
                                        >
                                            <ReactCountryFlag 
                                                style={{
                                                    fontSize: '1em',
                                                    lineHeight: '1em',
                                                }}
                                                countryCode={item.code=="en"?"gb":item.code} 
                                                
                                            />
                                        </Box>
                                        {item.description}
                                        {(language == item.code) && <Box
                                            position={"absolute"}
                                            right={0}
                                            paddingRight={"10px"}
                                        >                                                
                                            <MdOutlineCheck/>
                                        </Box>
                                        }
                                    </MenuItem>
                                </Link>
                                
                            ))}

                        </MenuGroup>
                    </MenuList>
                    </Portal>
                </Menu>
            </Flex>
        </>        
    )
}