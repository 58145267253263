import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { CreatureDefinitionType } from "@/app/types/creatureDefinitionType";
import {
    InputGroup,
    Input,
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, Image,
    Box,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Link,
    InputRightElement
} from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import { useContext, useState } from "react";
import { keyframes, css } from '@emotion/react';
import {FaFacebook, FaSearch} from "react-icons/fa";
import {RiInstagramFill} from "react-icons/ri";

const zoomAndShake = keyframes`
  0% {
   -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transform-origin: center;
    opacity: 0;
  }
  50% {
   -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
    opacity: 1;
  }
  100% {
   -webkit-transform: scale(1);
    transform: scale(1);
    transform-origin: center;
    opacity: 1;
  }
`;

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export default function FaqModal({isOpen,onClose}:Props) {

    const { t } = useTranslation();
    const [searchText, setSearchText] = useState<string>("");
    const {
        showLoginModal,
        setShowLoginModal,
        user,
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)
    
    const updateSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }

    return (
        <Modal isCentered  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxH="80vh" overflow={"hidden"} m={5}>
                <ModalHeader px={0} pt={2} pb={0} textAlign={"center"}>{t("faq.title")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={1} py={5} overflow="auto">
                    <Flex px={3} py={2} >
                        <InputGroup >                        
                            <Input onChange={updateSearchText} value={searchText} placeholder='Rechercher dans les FAQ'bg="#80808014"/>
                            <InputRightElement pr={2}>
                                <FaSearch />
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                    <Accordion allowToggle>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer0").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"} >
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold" }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question0")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer0")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer1").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question1")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer1")} <Trans
                                    components={{ strong: <strong/>}}
                                    i18nKey="game.noGeolocalisation"/>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer2").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question2")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer2")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer3").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question3")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer3")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer4").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question4")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer4")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer5").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question5")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer5")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer6").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question6")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer6")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer7").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question7")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer7")}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem display={(!searchText.length || searchText.length && t("faq.answer8").toLowerCase().includes(searchText.toLowerCase()))?"block":"none"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold"  }}>
                                <Box as='span' flex='1' textAlign='left'>
                                {t("faq.question8")}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} bg="#dbdbdb1c">
                                {t("faq.answer8")}
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion>
                    <Flex px={4} py={4} flexDirection={"column"}>
                        {t("faq.noAnswer")}
                        <Text>
                            <Link target="_blank" display="inline-flex" p={2} px={3} fontSize={"1.75em"} href={process.env.NEXT_PUBLIC_FACEBOOK_URL}><FaFacebook /></Link>
                            <Link target="_blank" display="inline-flex" p={2} px={3} fontSize={"1.75em"} href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}><RiInstagramFill /></Link>
                        </Text>
                    </Flex>
                </ModalBody>
                <ModalFooter px={2} py={2} display="flex">
                        <Button
                            onClick={onClose}
                            size="sm"
                        >
                            {t("game.close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )

}