'use client'
import { Switch, DrawerFooter, Avatar, Button, Card, CardBody, CircularProgress, CircularProgressLabel, Image, Text, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, useDisclosure, Stack, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, Link, Badge, Skeleton, Divider, IconButton, MenuList, MenuGroup, MenuItem, SimpleGrid } from "@chakra-ui/react";
import { MdCancel, MdCreate, MdLogout, MdMenu, MdPerson } from "react-icons/md";
import ScoreBoard from "./scoreBoard";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { useTranslation } from "next-i18next";
import { getLevel, getLevelName } from "@/app/helpers/levelConversionHelper";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { ConfirmLogout } from "../../context/authentication/layout/confirm/confirmLogout";
import { ConfirmDeleteAccount } from "../../context/authentication/layout/confirm/confirmDeleteAccount";
import { FaUserCircle } from "react-icons/fa";
import FriendRequestQrCode from "../../context/friends/layout/friendRequestQrCode";
import { FriendsContext } from "@/app/context/friends/friendsContext";
import { getFriendRequests, getFriends, getFriendsWithState } from "@/app/context/friends/helper/friendRequestsHelper";
import { SearchBarAddFriend } from "@/app/context/friends/layout/modal/searchBarAddFriend";
import { BsCheckCircleFill } from "react-icons/bs";
import { GrMoreVertical } from "react-icons/gr";
import { ScoreBar } from "./scoreBar";
import { AllBadges } from "@/app/const/allBadgesConst";
import { ConfigType } from "@/app/types/configType";
import { FaStar } from "react-icons/fa";
import {TiStarFullOutline} from 'react-icons/ti'
import { BadgeType } from "@/app/types/badge";
import {PaymentContext} from "@/app/context/payment/paymentContext";
import { numberFormatterHelper } from "@/app/helpers/numberFormatterHelper";
import { UserStateType } from "@/app/types/userStateType";
import { UnblockedConst } from "@/app/const/unblockedConst";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";

export default function AccountButton() {
    const { t } = useTranslation();
    const { isOpen:isOpenDrawer, onOpen:onOpenDrawer, onClose:onCloseDrawer } = useDisclosure()
    const {isOpen: isOpenLogout, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure()
    const { isOpen: isOpenDeleteAccount, onOpen: onOpenDeleteAccount, onClose:onCloseDeleteAccount } = useDisclosure()

    const [openIndex, setOpenIndex] = useState<number | null>(null); // Pour stocker l'index ouvert

    // Fonction qui s'exécute lorsque l'accordéon change
    const handleAccordionChange = (expandedIndex: number | number[] | null) => {
        if (Array.isArray(expandedIndex)) {
            setOpenIndex(expandedIndex.length > 0 ? expandedIndex[0] : null); // Si plusieurs sont ouverts, on prend le premier
          } else {
            setOpenIndex(expandedIndex);
          }
    };
    
    const {
        language
    } = useContext(GlobalDataContext)

    const {
        user,
        setShowLoginModal,
        messagingToken,
        setLogOut,
        isAuthLoading,
        updateProfileName,
        //cloudStorage
    } = useContext(AuthenticationContext)

    const {
        score,
        isGlobalDataUserLoading,
        config,
        setConfig,
        userState,
        userInfo,
        setUserInfo,
        addFood,
        addNewCreature,
        gotBonusFromBadgeReducer,
        setShowFriends
        //isNewsletterFromMailchimp,
        //setIsNewsletterFromMailchimp,
        //isLoadingMailchimp
    } = useContext(GlobalDataUserContext)
    
    const {
        canShowFriends,
        friendRequestWithUser,
        showDeleteFriendRequestUserId,
        setShowDeleteFriendRequestUserId,
        isLoadingFriends,
        approveFriend,
        allUserStates,
        friends,
        friendsWithState
    } = useContext(FriendsContext)
    
    const {
        allPayment
    } = useContext(PaymentContext)  

    const isUserOnline = (lastActivity:number) => {
        const fifteenMinutesAgo = new Date(Date.now() - 15 * 60 * 1000).getTime();
        return lastActivity > fifteenMinutesAgo;
    };

    const onChangeFart = () => {
        setConfig ( configState => ({...configState, allowFart:!configState?.allowFart} as ConfigType))
    }

    const onChangeAutoVocal = () => {
        setConfig ( configState => ({...configState, allowAutoVocal:!configState?.allowAutoVocal} as ConfigType))
    }

    const onChangeNotification = () => {
        setConfig ( configState => ({...configState, allowNotifications:!configState?.allowNotifications} as ConfigType))
    }

    const onChangeBeep = () => {
        setConfig ( configState => ({...configState, allowBeep:!configState?.allowBeep} as ConfigType))
    }

    

    const isBonusToGet=()=>{
        const allBadges = AllBadges
        if(userState && userState.badges){
            const badges = userState.badges
            return allBadges.some(badge => {
                return badges.some(badgeUser => {
                    return badgeUser.id == badge.id && badge.gift && !badgeUser.gotBonus
                })
            })
        }
    }

    const unblockedContent = useMemo(() => {
        return (
          <SimpleGrid columns={3} spacing={3} w="full">
            {userState?.unblocked && userState?.unblocked.map((unblocked: any, index:number) => {
              const realUnblocked = UnblockedConst.find((b:any) => b.id === unblocked);
              if (!realUnblocked) return null; // Si pas de badge trouvé, ne rien rendre
    
              return (
                <Flex
                    key={index}
                    position="relative"
                    w="full"
                    justifyContent="center"
                    alignItems="center"
                    flexDir={"column"}
                    borderRadius={8}
                    overflow={"hidden"}
                    fontSize={"2xl"}
                    bg="black"
                    color="white"
                    py={1}
                >
                    <Box py="5">
                        {realUnblocked.icon}
                    </Box>
                    <Text fontSize={"0.5em"} lineHeight="1em" px={2} py={1}>
                        {realUnblocked.text[language]}
                    </Text>
                </Flex>
              );
            })}
            {UnblockedConst.map((unblocked: any, index:number) => {
                if (userState?.unblocked && userState?.unblocked.includes(unblocked.id)) return null; // Si pas de badge trouvé, ne rien rendre
        
                return (
                    <Flex
                        key={"u"+index}
                        position="relative"
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                        flexDir={"column"}
                        borderRadius={8}
                        overflow={"hidden"}
                        fontSize={"2xl"}
                        bg="black"
                        color="white"
                        py={1}
                        opacity={0.1}
                    >
                        <Box py="5">
                            {unblocked.icon}
                        </Box>
                        <Text fontSize={"0.5em"} lineHeight="1em" px={2} py={1}>
                            {unblocked.text[language]}
                        </Text>
                    </Flex>
                )
            })}
          </SimpleGrid>
        );
    }, [userState]);
   
    const badgesContent = useMemo(() => {
        if (!userState || !userState.badges || userState.badges.length === 0) {
        return null; // Si aucun badge, ne pas afficher le SimpleGrid
        }
        const showBonus=(badgeId:string, bonus:string)=>{
            switch(bonus){
                case "100food":
                    addFood(100)
                    break;

                case "creature":
                    addNewCreature(null, Date.now()-1000*60*60*24*5)
                    break;
            }
            gotBonusFromBadgeReducer(badgeId)
        }
        return (
        <SimpleGrid columns={3} spacing={3} w="full">
            {userState.badges.map((badge: any, index:number) => {
            const realBadge = AllBadges.find((b:any) => b.id === badge.id);
            if (!realBadge) return null; // Si pas de badge trouvé, ne rien rendre
    
            return (
                <Flex
                key={index}
                position="relative"
                w="full"
                className={realBadge.gift && !badge.gotBonus ? 'pulse-yellow' : ''}
                justifyContent="center"
                alignItems="center"
                flexDir={"column"}
                borderRadius={8}
                overflow={"hidden"}
                >
                <Image src={realBadge.url} alt={realBadge.name} width="87px" height="87px" />
                <Text fontSize={"0.8em"} lineHeight="1em" px={2} py={1} background={realBadge.bg ?? "#2a9ce1"} color={realBadge.color ?? "white"}>
                    {t("badges." + realBadge.name)}
                </Text>
                <Badge position="absolute" top={1} right={1} background={realBadge.bg ?? "#2a9ce1"} color={realBadge.color ?? "white"} borderRadius={5}>
                    {realBadge.label}
                </Badge>
                {realBadge.gift && !badge.gotBonus && (
                    <Box
                    onClick={() => showBonus(badge.id, realBadge.gift as string)}
                    position={"absolute"}
                    top={0}
                    left={0}
                    background={"#0c0c0b87"}
                    alignContent="center"
                    margin="auto"
                    color={"#ffbf00"}
                    w="full"
                    h="full"
                    fontSize={"3em"}
                    >
                    <TiStarFullOutline className="rotating" style={{ margin: "auto" }} />
                    </Box>
                )}
                </Flex>
            );
            })}
        </SimpleGrid>
        );
    }, [ t, userState]);
    
    const friendRequests = useMemo(() => friendRequestWithUser && getFriendRequests(friendRequestWithUser), [friendRequestWithUser]);

    // Mémoriser le contenu des friendRequests et des friends
    const friendRequestsContent = useMemo(() => {
        if (friendRequests && friendRequests.length > 0) {
        return friendRequests.map((item, index) => (
            <Box key={index}>
            <Flex
                background={index % 2 !== 0 ? "#ffe09926" : "transparent"}
                py={1}
                alignItems="center"
            >
                <Box position="relative">
                <Avatar transform="scale(0.8)" src={item.user.photoUrl ? item.user.photoUrl : `https://ui-avatars.com/api/?name=${item.user.name}`} icon={undefined} />
                </Box>
    
                <Box ml="1" w="full" textAlign="start">
                <Text fontWeight="bold">{item.user.name}</Text>
                </Box>
    
                <Box cursor="pointer" onClick={() => setShowDeleteFriendRequestUserId(item.id)} alignSelf="center" pr={2} color="red" borderRadius="50%">
                <MdCancel size="30px" />
                </Box>
    
                <Box cursor="pointer" onClick={() => approveFriend(item.id)} alignSelf="center" ml={2} pr={2} color="green" borderRadius="50%">
                <BsCheckCircleFill size="30px" />
                </Box>
            </Flex>
            </Box>
        ));
        }
        return null;
    }, [approveFriend, friendRequests, setShowDeleteFriendRequestUserId]);
    
    const friendsContent = useMemo(() => {
        if (friendsWithState && friendsWithState.length > 0) {
            return friendsWithState.map((item: any, index: number) => (
                <Flex key={index} background={index % 2 !== 0 ? "#bdbdbd26" : "transparent"} py={1}>
                <Box position="relative">
                    <CircularProgress size="49px" value={getLevel(item.state.points || 0) * 10} color="#dd6b20" trackColor="#b5b5b578">
                    <CircularProgressLabel p="4px">
                        <Image
                        alt="avatar"
                        src={item.user.photoUrl ? item.user.photoUrl : `https://ui-avatars.com/api/?name=${item.user.name}`}
                        width="40px"
                        height="40px"
                        borderRadius="50%"
                        />
                    </CircularProgressLabel>
                    </CircularProgress>
        
                    <Badge position="absolute" top={0} right={0} mx="1" bg="#2a9ce1" color="white">
                    {index + 1}
                    </Badge>
                    {isUserOnline(item.state.lastActivity ?? item.user.lastActivity) && (
                    <Badge position="absolute" bottom={2} border="2px solid white" right={0} mx="1" bg="#05b13b" width="14px" height="14px" borderRadius={10}></Badge>
                    )}
                </Box>
        
                <Box ml="1" w="full" textAlign="start">
                    <Text fontWeight="bold">{item.user.name}</Text>
                    <Text fontSize="0.7em" lineHeight="0.7em" opacity={0.8}>
                    {getLevelName(item.state.points || 0)}
                    </Text>
                    <ScoreBar state={item.state} />
                </Box>
        
                <Box alignSelf="center" pr={2}>
                    {item.id !== "0" && (
                    <Menu>
                        <MenuButton background="transparent" as={IconButton} aria-label="Options" icon={<GrMoreVertical />} />
                        <MenuList>
                        <MenuGroup title="Actions">
                            <MenuItem onClick={() => setShowDeleteFriendRequestUserId(item.id)}>
                            {t("friends.removeFriend")}
                            </MenuItem>
                        </MenuGroup>
                        </MenuList>
                    </Menu>
                    )}
                </Box>
                </Flex>
            ));
            }
            return null;
    }, [friendsWithState, setShowDeleteFriendRequestUserId, t]);
      

         // Utilisation du useEffect pour détecter quand l'accordéon des amis est ouvert
    useEffect(() => {
        if (openIndex === 0) {  // Supposons que l'item des amis soit à l'index 0
          setShowFriends(true);
        } else {
          setShowFriends(false);
        }
      }, [openIndex]);

    return (
        <>
            { !user &&
                <Link
                    onClick={()=>setShowLoginModal(true)}
                >
                    <Button
                        display={"flex"}
                        background={"transparent!important"}
                        p={1}
                        m={0}
                        color="white"
                        fontSize={"1.5em"}
                    >
                        <FaUserCircle />                    
                    </Button>                   
                </Link>
            }
            {user && <> 
                <Button
                    display={"flex"}
                    background={"transparent!important"}
                    p={0}
                    m={0}
                    color="white"
                    onClick={onOpenDrawer}
                    position="relative"
                >                    
                    <Avatar
                        src={user.photoURL as string}
                        size={"sm"}
                        icon={undefined}
                    />
                     {canShowFriends && friendRequestWithUser && getFriendRequests(friendRequestWithUser).length > 0  && <Badge position={"absolute"} bottom={0} right={0} className={"pulse"} ml={2} borderRadius={4} background='red' color={"white"}>{getFriendRequests(friendRequestWithUser).length}</Badge>}
                     {isBonusToGet() && <Badge ml={2} position="absolute" top="1" right="1" background='orange' color={"white"} w="5px" h="5px" paddingInline={0} borderRadius={"50%"}></Badge>}  
                </Button>
                <Drawer
                    isOpen={isOpenDrawer}
                    placement='right'
                    onClose={onCloseDrawer}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader pt={2} pb={0} px={4}>{t("account.title")}</DrawerHeader>

                        <DrawerBody px={3}>
                            <Flex
                                w='full'
                                placeContent={"center"}
                                pt={0}
                                pb={4}
                            >
                                <FriendRequestQrCode senderId={user.uid}/>                                           
                            </Flex>
                            <Card
                                direction={'row'}
                                overflow='hidden'
                                variant='outline'                         
                            >
                                    <CardBody 
                                        pb={0}
                                        width={"100%"} 
                                        maxWidth={"100%"}  
                                        px={3}
                                        textAlign="center"
                                        pt={2}
                                    >                                                                               
                                        
                                        <InputGroup>
                                            <Input
                                                variant='flushed'
                                                placeholder={t("header.enterPseudo") as string}
                                                defaultValue={(userInfo && userInfo.userName) ?? user.displayName as string}
                                                maxLength={50}
                                            />
                                        </InputGroup>
                                        <Stack
                                            pt={3}
                                            borderRadius={3}
                                        >
                                            <ScoreBoard/>
                                        </Stack>
                                        <Accordion allowToggle marginTop={3} onChange={handleAccordionChange}>                                        
                                        <>
                                            {canShowFriends && friendRequestWithUser && (
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex="1" textAlign="left">
                                                    {t("friends.longTitle")}
                                                    {friends && friends.length > 0 && <Badge ml={2} borderRadius={4} background="#393328" color="white">{friends.length}</Badge>}
                                                    {friendRequests && friendRequests.length > 0 && <Badge ml={2} borderRadius={4} background="red" color="white">{friendRequests.length}</Badge>}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                    
                                                <AccordionPanel pb={4} px={0} pt={0}>
                                                {isLoadingFriends && (
                                                    <Stack>
                                                    <Skeleton height="20px" />
                                                    <Skeleton height="20px" />
                                                    <Skeleton height="20px" />
                                                    </Stack>
                                                )}
                                    
                                                {!isLoadingFriends && (
                                                    <>
                                                    {friendRequests && friendRequests.length > 0 && (
                                                        <>
                                                        <Text opacity={0.8}>
                                                            <small>{t("friends.requestToApprove")}</small>
                                                        </Text>
                                                        {friendRequestsContent}
                                                        <Divider marginY={2} />
                                                        </>
                                                    )}
                                    
                                                    {friends && friends.length > 0 && (
                                                        <>
                                                        <Text opacity={0.8} textAlign={"start"}>
                                                            <small>{t("friends.ranking")}</small>
                                                        </Text>
                                                        {friendsContent}
                                                        </>
                                                    )}
                                    
                                                    {!friends || !friends.length && (
                                                        <Flex>
                                                        <Text>{t("friends.stillNoFriends")}</Text>
                                                        </Flex>
                                                    )}
                                                    </>
                                                )}
                                                <SearchBarAddFriend />
                                                </AccordionPanel>
                                            </AccordionItem>
                                            )}
                                        </>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                    {t("account.myBadges")}
                                                    {isBonusToGet() && <Badge ml={2} background='orange' color={"white"} w="5px" h="5px" paddingInline={0} borderRadius={"50%"}></Badge>}
                                                    {userState && userState.badges  && <Badge ml={2} borderRadius={4} background='#393328' color={"white"}>{userState.badges.length}</Badge>}                                                    
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} px={0} overflow="visible">
                                                
                                                        <Flex
                                                            key={0}
                                                            background={0 % 2 != 0?"#ffe09926":"transparent"}
                                                            py={1}
                                                        >
                                                            {badgesContent && badgesContent}
                                                            {(userState && (!userState.badges || !userState.badges.length ))&& <Text>{t("badges.noBadges")}</Text>}
                                                        </Flex>                                            
                                                
                                                </AccordionPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                    {t("account.unblocked")}                                                    
                                                    {userState && userState.unblocked  && <Badge ml={2} borderRadius={4} background='#393328' color={"white"}>{userState.unblocked.length}</Badge>}                                                    
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} px={0} overflow="visible">
                                                
                                                        <Flex
                                                            key={0}
                                                            background={0 % 2 != 0?"#ffe09926":"transparent"}
                                                            py={1}
                                                        >
                                                            {unblockedContent && unblockedContent}
                                                        </Flex>                                            
                                                
                                                </AccordionPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                    {t("account.myPayments")}
                                                    {allPayment && <Badge ml={2} borderRadius={4} background='#393328' color={"white"}>{allPayment.length}</Badge>}                                                    
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} px={0}>                                         
                                                    {allPayment.length > 0 && allPayment.map((item, index) =>
                                                        <Flex
                                                            key={index}
                                                            background={index % 2 != 0?"#ffe09926":"transparent"}
                                                            py={1}
                                                        >
                                                            <Text>{item.itemId}</Text>
                                                            <Text pl={1} opacity="0.7" margin="auto" fontSize='xs'>({new Date(item.timestamp).toLocaleDateString('en-GB')})</Text>
                                                            <Box
                                                                flex={1}
                                                                textAlign={"right"}
                                                            >
                                                                {item.price} €
                                                            </Box>
                                                        </Flex>
                                                    )}

                                                    {!allPayment.length &&
                                                        <Flex>
                                                            <Text textAlign="start">{t("account.noContentPaid")}</Text>
                                                        </Flex>
                                                    }
                                                </AccordionPanel>
                                            </AccordionItem>

                                            {config && <AccordionItem borderBottom={0}>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        {t("account.myOptions")}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} px={2} textAlign={"left"}>
                                                    <Flex py={1}><Text w="full" onClick={onChangeFart}>{t("account.allowFart")}</Text><Switch colorScheme="teal" onChange={onChangeFart} isChecked={config.allowFart} /></Flex>
                                                    <Flex py={1}><Text w="full" onClick={onChangeAutoVocal}>{t("account.allowAutoVocal")}</Text><Switch colorScheme="teal" onChange={onChangeAutoVocal} isChecked={config.allowAutoVocal}/></Flex>
                                                    <Flex py={1}><Text w="full" onClick={onChangeBeep}>{t("account.allowBeep")}</Text><Switch colorScheme="teal" onChange={onChangeBeep} isChecked={config.allowBeep}/></Flex>
                                                    <Flex py={1}><Text w="full" onClick={onChangeNotification}>{t("account.allowNotifications")}</Text><Switch colorScheme="teal" onChange={onChangeNotification} isChecked={config.allowNotifications}/></Flex>
                                                </AccordionPanel>
                                            </AccordionItem>}


                                            <AccordionItem borderBottom={0}>
                                                <h2>
                                                <AccordionButton px={0}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        {t("account.manageAccount")}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} px={2}>
                                                    <Text
                                                        opacity={0.5}
                                                        textAlign="start"
                                                        pb={3}
                                                    >
                                                        {user.email}
                                                    </Text>
                                                    <Text
                                                        color={"red"}
                                                        cursor={"pointer"}
                                                        onClick={onOpenDeleteAccount}
                                                    >
                                                        {t("account.deleteAccount")}
                                                    </Text>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>                                             
                                    </CardBody>
                            </Card>                            
                        </DrawerBody>
                        <DrawerFooter py={2} pb={0} px={2}>
                            <Flex
                                py={2}
                                px={1}
                                justifyContent="end"
                            >
                                <Button
                                    colorScheme="orange"
                                    size="sm"
                                    onClick={onOpenLogout}
                                >
                                    {t("account.logout")}
                                </Button>
                            </Flex> 
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>

                <ConfirmLogout
                    isOpen={isOpenLogout}
                    onClose={onCloseLogout}
                    setLogOut={setLogOut}
                />

                <ConfirmDeleteAccount
                    isOpen={isOpenDeleteAccount}
                    onClose={onCloseDeleteAccount}
                />
            </>}
        </>        
    )
}