'use client'
import { PortalManager, Button, Text, Flex, useDisclosure, SkeletonCircle, Link, Badge } from "@chakra-ui/react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useContext } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import AccountButton from "./AccountButton";
import NextLink from "next/link";
import { GiCupcake } from "react-icons/gi";
import { useRouter } from "next/router";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import MenuDrawer from "./menuDrawer";
import { ConfirmQuitGame } from "@/app/context/global/layout/modal/confirmQuitGame";
import TradeRequest from "./tradeRequests";
import { PaymentContext } from "@/app/context/payment/paymentContext";
import FaqModal from "@/app/context/global/layout/modal/faqModal";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import { FaQuestionCircle } from "react-icons/fa";


type Props = {
    allowBackButton?: boolean;
    urlBack?: string;
    title?:string
    confirmQuitPage?: boolean
    className?: string
};

export default function Header({allowBackButton=false, className, urlBack, title, confirmQuitPage}: Props) {
    
    const router = useRouter()
    const {
        isAuthLoading,
        user
    } = useContext(AuthenticationContext)
    
    const {
        userState,
        userInfo,
        tradeRequests
    } = useContext(GlobalDataUserContext)

    const {
        setShowPaidContentModal
    }=useContext(PaymentContext)    

    const {
        setDoVibrate,
        addMessageWithId,
        isRecentMessage,
        addModalToQueue,
        character
    } = useContext(NotificationContext)
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const onConfirmQuit = ()=>{
        onClose()
        router.push(urlBack as string, undefined, { shallow: true })
    }

    const openFaqModal = ()=>{
        addModalToQueue({
            component: FaqModal,
            props: {},
            doConfetti: false,
            doVibrate: false,
        })
    }

    return (
        <>
        <PortalManager zIndex={100}>
            <Flex
                borderTop={(userInfo && userInfo.isAdmin)?"3px solid #ffb100d6":"transparent"}
                w="full" 
                color="white" 
                position="absolute" 
                p={3} 
                px={3}
                backdropFilter="blur(10px)" 
                alignItems="center"
                zIndex={100}
                top={0}
                className={className}
            >
                {(!allowBackButton ) &&
                    <MenuDrawer/>
                }
                {(allowBackButton && urlBack && !confirmQuitPage) && <Link as={NextLink} href={urlBack} shallow>
                    <Button
                        display={"flex"}
                        background={"transparent!important"}
                        p={1}
                        m={0}
                        color="white"
                        fontSize={"1.5em"}
                    >
                            <MdArrowBackIosNew />
                    </Button>
                </Link>}
                
                {(allowBackButton && urlBack && confirmQuitPage) && 
                    <Button
                        display={"flex"}
                        background={"transparent!important"}
                        p={1}
                        m={0}
                        color="white"
                        fontSize={"1.5em"}
                        onClick={onOpen}
                    >
                            <MdArrowBackIosNew />
                    </Button>
                }

                <Text
                    flex={1}
                    textAlign="left"
                    fontSize="1.25em"
                    fontWeight="bold"
                    px={2}
                    textOverflow="ellipsis"
                    overflow=" hidden"
                    whiteSpace="nowrap"
                >
                    {title && <>{title}</>}
                    {!title && <>The Gooblies</>}
                </Text>
                <Button 
                    display={"flex"}
                    background={"transparent!important"}
                    p={1}
                    pr={4}
                    m={0}
                    color="white"
                    fontSize={"1.25em"}
                    position="relative"
                    onClick={openFaqModal}>
                    <FaQuestionCircle />
                </Button>
                    {isAuthLoading && <>
                        <SkeletonCircle/>
                    </>}

                    {!isAuthLoading && <>   
                        <TradeRequest/>
                        <Button
                            display={"flex"}
                            background={"transparent!important"}
                            p={1}
                            pr={4}
                            m={0}
                            color="white"
                            fontSize={"1.5em"}
                            position="relative"
                            onClick={()=>{setShowPaidContentModal({itemId:"food1000"})}}
                        >
                                <GiCupcake />
                                <Badge position="absolute" bottom={0} right={0} bg="#af997d" color='black'>{userState?.food}</Badge>
                        </Button>                                             
                        <AccountButton/>
                    </>}
            </Flex>
            </PortalManager>
            <ConfirmQuitGame isOpen={isOpen} onClose={onClose} onConfirmQuit={onConfirmQuit} />
        </>
    )
}
