import { LiveScoreType } from "../types/liveScoreType"

export const getPointsFromLiveScoreHelper= (liveScore:LiveScoreType[],startTime:number) => {
    if ( process.env.NODE_ENV==="development" ) {
        //console.log("getPointsFromLiveScoreHelper", liveScore, startTime)     
    }
    if ( !startTime || startTime == 0){
        return 0
    }
    const compare = Date.now() - startTime
    let points = 0
    if ( liveScore && liveScore.length){
        liveScore.forEach((item)=>{
            if (item.timestamp<= compare){
                points = points + item.points
            }
        })
    }
    return points
}