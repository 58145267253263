import { Flex, HStack, VStack } from "@chakra-ui/react"
import { LegacyRef } from "react"

type Props = {
    header: React.ReactElement
    menu: React.ReactElement
    background?: React.ReactElement
    children?: React.ReactElement
    footer: React.ReactElement
    friends: React.ReactElement
    scrollContainerRef?: LegacyRef<HTMLDivElement> | null
}

export default function Skeleton({header, menu, background, children, footer, scrollContainerRef, friends}:Props) {

    return (
        <HStack 
            gap={0}
            width={"full"}
            maxW={"1280px"}
            boxShadow={"0 .5rem 1rem rgba(0,0,0,.15)"} 
            h={"var(--doc-height)"}
            margin="auto"
            w="full"
        >
            <Flex position="relative" flexDirection={"column"} h="full" w="full" background="linear-gradient(rgb(0, 0, 0) 0%, #1a0c04 100%);">  
                    
                    <>
                        {header}
                    </>
                <HStack w="full" h="full" overflowY={"hidden"}>
                    <Flex display={{base:"none", xl:"flex"}} flexDirection="column" w="300px" minW="300px" bg="transparent" color="white"  h="full" px={3} py={2} pt={"64px"}> 
                        <>
                            {menu}
                        </>
                    </Flex>
                    <VStack w="full" h="full" position="relative">
                    {background && <Flex position="absolute" top="0" left="0" w="full" h="full" maxH="100vh" opacity={0.8} overflowY="hidden">
                        <Flex position="relative" w="full" h="full" direction="column" p={0} overflowY="auto" >
                            {background}
                        </Flex>
                    </Flex>}    
                        <Flex zIndex={1} w="full" position="absolute" top={0} left={0} h="full" backdropFilter={"blur(50px)"}  ></Flex>
                        <Flex zIndex={2} position="relative" ref={scrollContainerRef} w="full" h="full" direction="column" p={0} pt={"64px"} overflowY="auto" scrollBehavior={"smooth"}>
                            {children}
                        </Flex>
                        {footer}
                    </VStack>
                </HStack>
            </Flex>
        </HStack>

    )
}