import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { FocusableElement } from "@chakra-ui/dom-utils";
import { AlertDialog, AlertDialogContent } from "@chakra-ui/modal";
import { AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React, { MouseEventHandler, MutableRefObject, useContext } from "react";

type Props = {
    isOpen: boolean
    onClose: MouseEventHandler<HTMLButtonElement> | undefined
}

export const ConfirmDeleteAccount = ({isOpen, onClose }:Props) => {
    const cancelRef: MutableRefObject<FocusableElement | undefined> = React.useRef()
    const { t } = useTranslation();

    const {
        deleteUserConnected
    }= useContext(AuthenticationContext)

    const onDelete = async()=> {
        deleteUserConnected( )
    }

    return(
        <AlertDialog
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef as MutableRefObject<any>}
            onClose={()=>onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent
                >
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("authentication.deleteMyAccount")}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {t("authentication.deleteAccount.question")}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            size="sm"
                            ref={cancelRef as MutableRefObject<any>}
                            onClick={onClose}>
                            {t("authentication.deleteAccount.no")}
                        </Button>
                        <Button size="sm" colorScheme='red' onClick={onDelete} ml={3}>
                            {t("authentication.deleteAccount.yes")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
