import React, { Dispatch, MouseEventHandler, MutableRefObject } from "react";
import { AlertDialog, AlertDialogContent } from "@chakra-ui/modal";
import { AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FocusableElement } from "@chakra-ui/dom-utils";

type Props = {
    isOpen: boolean
    onClose: MouseEventHandler<HTMLButtonElement>
    setLogOut: Dispatch<React.SetStateAction<boolean>>
}

export const ConfirmLogout = ({isOpen, onClose, setLogOut }:Props) => {
    const cancelRef: MutableRefObject<FocusableElement | undefined> = React.useRef()
    const { t } = useTranslation();

    const forceLogout= (e:any)=>{
        onClose(e)
        setLogOut(true)
    }

    return(
        <AlertDialog
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef as MutableRefObject<any>}
            onClose={()=>onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent
                >
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("authentication.logout")}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {t("authentication.logoutConfirm.question")}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            size="sm"
                            ref={cancelRef as MutableRefObject<any>}
                            onClick={onClose}>
                            {t("authentication.logoutConfirm.no")}
                        </Button>
                        <Button size="sm" colorScheme='orange' onClick={forceLogout} ml={3}>
                            {t("authentication.logoutConfirm.yes")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
