import { Flex, Avatar, Button, Image, Modal, ModalBody, Text, Box, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Alert, AlertIcon, Drawer, DrawerOverlay, DrawerHeader, DrawerBody, DrawerContent, DrawerFooter, Badge, VStack} from "@chakra-ui/react"
import { Trans, useTranslation } from "next-i18next"
import { TbEdit } from "react-icons/tb"
import {IoFootstepsSharp} from "react-icons/io5"
import { keyframes, css } from '@emotion/react';
import { useContext, useEffect, useState } from "react";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { ChallengeType } from "@/app/types/challengeType";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
const zoomAndShake = keyframes`
   0% {
   -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transform-origin: center;
  }
  50% {
   -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
  }
  100% {
   -webkit-transform: scale(1);
    transform: scale(1);
    transform-origin: center;
  }
`;

type Props = {
    isOpen: boolean
    onClose: ()=>void
    autoHide?: boolean
    indexesToAnimate?: number[]
}

export const DailyChallengeModal = ({isOpen, onClose, autoHide, indexesToAnimate}:Props) => {

    const { t } = useTranslation();
    const [localChallenge, setLocalChallenge] = useState<ChallengeType[]>([])
    const [localPoints, setLocalPoints] = useState<number>(0)
    const {
      getChallenge,
      isGlobalDataUserLoading,
      actionHistory
    }= useContext(GlobalDataUserContext)
    
    const {
      language
    }=useContext(GlobalDataContext)

    const {
      user,
      isAuthLoading,
      setShowLoginModal
    }=useContext(AuthenticationContext)

    useEffect(() => {
        if (isOpen && autoHide) {
          const timer = setTimeout(() => {
            onClose();
          }, 3000); // Fermer après 5 secondes
    
          return () => clearTimeout(timer); // Nettoyage pour éviter des problèmes si la modal est fermée manuellement
        }
    }, [isOpen, onClose]);

    useEffect(() => {
        if ( !isGlobalDataUserLoading){
          const challenge = getChallenge()
          setLocalChallenge(challenge)
          let points = 0
          challenge && challenge.map((challenge: any) => {
            points += challenge.points
          })
          setLocalPoints(points)
        }
    }, [isGlobalDataUserLoading, actionHistory])

    return (
        <>
        <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader  px={3} py={2}>{t("dailyChallenge.title")} {!isAuthLoading && user && <Badge ml='1' variant='solid' fontSize='0.8em' colorScheme='pink' borderRadius={4} px={2}>{localPoints} XP</Badge>}</DrawerHeader>
                {!isAuthLoading && user && <DrawerBody className="paper-notes" px={4}> 
                    <VStack alignItems={"start"} gap={0}>
                    {localChallenge && localChallenge.map((challenge, index) => (
                        <Text key={index} className={(challenge.checked?"strikethrough-draw" + (indexesToAnimate?.includes(index)?" ": " no-animation"):"") }>{index+1}. {challenge.title[language].replace('{{x}}', `${challenge.quantity}`)}</Text>
                    ))}
                    </VStack>
                </DrawerBody>}
                {!isAuthLoading && !user && <DrawerBody px={2}> 
                    <VStack alignItems={"start"} gap={0}>
                      <Alert status="warning">
                        <AlertIcon />
                        <VStack>
                            <Text>{t("dailyChallenge.mustBeConnected")}</Text>
                            <Button onClick={()=>{setShowLoginModal(true)}} colorScheme="orange" size="sm" mt={2}>{t("quest.login")}</Button> 
                        </VStack>
                        </Alert>
                    </VStack>
                </DrawerBody>}
                <DrawerFooter px={3} py={2} >
                    <Button display={autoHide?"none":"block"} size="sm" onClick={onClose} pr={4}>{t("game.close")}</Button>  
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
        </>
    )
}