'use client'
import { Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { useRouter } from "next/router";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import AnaDiscussion from "@/app/context/notification/layout/anaDiscussion";

type Props = {
    background?: string
    children?: React.ReactNode;
};

export default function Footer({background, children}: Props) {
    
    const router = useRouter()
    const {
        isAuthLoading,
        user
    } = useContext(AuthenticationContext)
    
    const {
        isBrowsingFromGame
    } = useContext(GlobalDataContext)

    const {
        isGlobalDataUserLoading
    } = useContext(GlobalDataUserContext)
    
    return (
        <>
        <Flex zIndex={2} w="full" h="12.5vh" position="absolute" bottom="0" left="0" background={`linear-gradient(rgb(0 0 0 / 0%), ${background??"rgb(0 0 0 / 65% )"})`}></Flex>
        
        <Flex zIndex={10} w="full" justifyContent={"center"} position="absolute" bottom="4" direction={"column"} px={2}>
            {!isAuthLoading && !isGlobalDataUserLoading && <AnaDiscussion/>}
            {children}
        </Flex>
    </>
    )
}
