import { MyCreaturesType } from "../types/myCreaturesType";

export const  hasDuplicateCreatureId = (myCreatures:MyCreaturesType[]) =>{
    const creatureIds = new Set();

    for (const creature of myCreatures) {
        if (creatureIds.has(creature.creatureId)) {
            return true; // Un doublon est trouvé
        }
        creatureIds.add(creature.creatureId);
    }

    return false; // Aucun doublon trouvé
}